import Vue from "vue";
import VueI18n from "vue-i18n";
import store from "@/store/";
import en from "@/locales/en.json";
import th from "@/locales/th.json";
import vi from "@/locales/vi.json";
import Cookies from "js-cookie";

Vue.use(VueI18n);

const messages = {
  en: en.en,
  th: th.th,
  vi: vi.vi,
};

const getInitialLocale = () => {
  const localeFromStore = store?.state?.selectLang?.lang;
  const localeFromCookies = Cookies.get("user_lang");
  return localeFromStore || localeFromCookies || "en";
};

const i18n = new VueI18n({
  locale: getInitialLocale(),
  messages,
  fallbackLocale: "en",
  silentFallbackWarn: true,
});

export default i18n;
