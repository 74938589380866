export const BASE_COLOR = {
  blue: "#0064C5",
  red: "#B60000",
  green: "#006600",
  black: "#212121",
  white: "#FFF",
  middle_grey: "#5F6062",
  light_grey: "#9DA6AD",
  gold: "#B4975A",

  orange: "#E55F14",
  orange_100: "#F68809",
  orange_60: "#FAB86B",
  orange_30: "#FDE7CE",
  orange_10: "#FEF4E9",
  orange_5: "#9DA6AD",

  yellow: "#FCB034",
  yellow_100: "#FFD200",
  yellow_60: "#FFE466",
  yellow_30: "#FFF6CC",
  yellow_10: "#FFFBE5",
  yellow_5: "#FFFCF2",

  yellow_green_100: "#BFD728",
  yellow_green_60: "#D8E77E",
  yellow_green_30: "#F2F7D4",
  yellow_green_10: "#F9FBEA",
  yellow_green_5: "#9DA6AD",

  sky_blue_100: "#00A7CC",
  sky_blue_60: "#57C3DB",
  sky_blue_30: "#ABE1ED",
  sky_blue_10: "#D5F0F6",
  sky_blue_5: "#F2FAFC",

  purplish_blue_100: "#332A86",
  purplish_blue_60: "#827CB6",
  purplish_blue_30: "#C2BFDB",
  purplish_blue_10: "#E0DEED",
  purplish_blue_5: "#9DA6AD",

  purplish_red_100: "#C60651",
  purplish_red_60: "#D9598B",
  purplish_red_30: "#ECACC4",
  purplish_red_10: "#F6D5E2",
  purplish_red_5: "#FCF2F6",

  grey_100: "#646568",
  grey_60: "#97989B",
  grey_30: "#E5E5E6",
  grey_10: "#F2F2F2",
  grey_5: "#FBFBFB",
};

export const colors = {
  primary: {
    // ใช้เป็นสีสำหรับตกแต่ง เช่น เป็นพื้นหลัง หรือเป็นเส้นตกแต่ง
    orange: BASE_COLOR.orange,
    // เป็นสีหลักของ Font และ icon ต่างๆ
    black: BASE_COLOR.black,
    // ใช้เป็นสีพื้นเมื่อมีเนื้อหาอยู่ด้านบน
    white: BASE_COLOR.white,
    // ใช้เป็นสีสำหรับตกแต่ง เช่น เป็นพื้นหลัง หรือเป็นเส้นตกแต่ง
    yellow: BASE_COLOR.yellow,
  },
  secondary: {
    middle_grey: BASE_COLOR.middle_grey,
    light_grey: BASE_COLOR.light_grey,
    gold: BASE_COLOR.gold,
  },
  gradient: {
    // เป็นสีพื้นสำหรับปุ่มที่สำคัญที่สุดของหน้า
    orange_100: `linear-gradient(180deg, #FFCF36 0%, ${BASE_COLOR.yellow} 88.02%)`,
    // ใช้เป็นสีพื้นสำหรับ Secondary Button
    white_100: `linear-gradient(180deg, ${BASE_COLOR.white} 0%, #EFEFEF 88.02%)`,
    // ใช้เป็นสีพื้นสำหรับ DataSection ใน Info Web
    grey_100: `linear-gradient(180deg, #FAFAFA 0%, #F5F5F5 100%)`,
    // ใช้เป็นสีพื้นสำหรับ Secondary Button ของ Web info
    black_100: `linear-gradient(91.06deg, #7C7A7B 0.39%, rgba(35, 31, 32, 0.8) 100%)`,
    red: `linear-gradient(180deg, ${BASE_COLOR.red} 0%, #911313 100%)`,
  },
  transparent: {
    // เป็นสีพื้นหลัก
    yellow_30: "#FFD2004D",
    // Card Background Default (header, menu, less content)
    white_80: "#FFFFFFCC",
    // Card Background - Heavy Content
    white_96: "#FFFFFFF5",
    // Disable Button Background / Stroke Color for cross session line and text field
    black_10: "#0000001A",
    // Disable Button Background
    black_6: "#D6F1F9",
    // Text Disable
    black_35: "#00000059",
  },
  accessible: {
    // ใช้เป็นสีบอกสถานะว่ากำลังทำงานอยู่
    blue: BASE_COLOR.blue,
    // ใช้เป็นสีบอกสถานะว่าต้องทำเร่งด่วน
    red: BASE_COLOR.red,
    // ใช้เป็นสีบอกสถานะว่าทำเสร็จแล้ว
    green: BASE_COLOR.green,
    // ใช้เป็นสีบอกสถานะแจ้งเตือน
    orange: BASE_COLOR.orange,
  },
  tertiary: {
    orange: BASE_COLOR.orange,
    orange_100: BASE_COLOR.orange_100,
    orange_60: BASE_COLOR.orange_60,
    orange_30: BASE_COLOR.orange_30,
    orange_10: BASE_COLOR.orange_10,
    orange_5: BASE_COLOR.orange_5,

    yellow: BASE_COLOR.yellow,
    yellow_100: BASE_COLOR.yellow_100,
    yellow_60: BASE_COLOR.yellow_60,
    yellow_30: BASE_COLOR.yellow_30,
    yellow_10: BASE_COLOR.yellow_10,
    yellow_5: BASE_COLOR.yellow_5,

    yellow_green_100: BASE_COLOR.yellow_green_100,
    yellow_green_60: BASE_COLOR.yellow_green_60,
    yellow_green_30: BASE_COLOR.yellow_green_30,
    yellow_green_10: BASE_COLOR.yellow_green_10,
    yellow_green_5: BASE_COLOR.yellow_green_5,

    sky_blue_100: BASE_COLOR.sky_blue_100,
    sky_blue_60: BASE_COLOR.sky_blue_60,
    sky_blue_30: BASE_COLOR.sky_blue_30,
    sky_blue_10: BASE_COLOR.sky_blue_10,
    sky_blue_5: BASE_COLOR.sky_blue_5,

    purplish_blue_100: BASE_COLOR.purplish_blue_100,
    purplish_blue_60: BASE_COLOR.purplish_blue_60,
    purplish_blue_30: BASE_COLOR.purplish_blue_30,
    purplish_blue_10: BASE_COLOR.purplish_blue_10,
    purplish_blue_5: BASE_COLOR.purplish_blue_5,

    purplish_red_100: BASE_COLOR.purplish_red_100,
    purplish_red_60: BASE_COLOR.purplish_red_60,
    purplish_red_30: BASE_COLOR.purplish_red_30,
    purplish_red_10: BASE_COLOR.purplish_red_10,
    purplish_red_5: BASE_COLOR.purplish_red_5,

    grey_100: BASE_COLOR.grey_100,
    grey_60: BASE_COLOR.grey_60,
    grey_30: BASE_COLOR.grey_30,
    grey_10: BASE_COLOR.grey_10,
    grey_5: BASE_COLOR.grey_5,
  },
};

export const typography = {
  fontSizes: {
    xs: "10px",
    sm: "11px",
    md: "14px",
    lg: "22px",
    xl: "30px",
    xxl: "40px",
  },
  fontWeights: {
    heading: 800,
    normal: "normal",
    bold: "bold",
  },
  lineHeights: {
    normal: "145%",
    pdf_h1: "14px",
    pdf: "170%",
  },
};

// export const breakpoints = {
//   xxxs: 280,
//   xxs: 375,
//   xs: 576,
//   sm: 768,
//   md: 992,
//   lg: 1200,
//   xl: 1400,
// }

// export const theme = {
//   colors: colors,
//   typography: typography,
//   borderRadius: {
//     default: '100px',
//     full: '9999px',
//     input: '4px',
//     button: '8px',
//     card: '16px',
//     tab: '8px',
//   },
//   screen: {
//     xxxs: `@media (max-width: ${breakpoints.xxxs}px)`,
//     xxs: `@media (max-width: ${breakpoints.xxs}px)`,
//     xs: `@media (max-width: ${breakpoints.xs}px)`,
//     sm: `@media (max-width: ${breakpoints.sm}px)`,
//     md: `@media (max-width: ${breakpoints.md}px)`,
//     lg: `@media (max-width: ${breakpoints.lg}px)`,
//     xl: `@media (max-width: ${breakpoints.xl}px)`,
//   },
//   breakpoint: breakpoints,
// }
