import {
  H1Style,
  H2Style,
  H3Style,
  H4Style,
  BodyStyle,
  BodyLinkStyle,
  BodyBoldStyle,
  BodySmallStyle,
  BodyBoldSmallStyle,
  H1PDFStyle,
  H2PDFStyle,
  BodyPDFStyle,
  BodyBoldPDFStyle,
} from "../../styles/typo.styled";
import { colors } from "../../styles/theme.styled";
import styled from "vue-styled-components";

export const ellipsis = styled.div`
  padding-left: 1px;
  text-overflow: ellipsis;
  color: var(--primary-black);
`;

export const H1_Web = styled(ellipsis)`
  ${H1Style}
  ${colors}
`;

export const H2_Web = styled(ellipsis)`
  ${H2Style}
  ${colors}
`;

export const H3_Web = styled(ellipsis)`
  ${H3Style}
  ${colors}
`;

export const H4_Web = styled(ellipsis)`
  ${H4Style}
  ${colors}
`;

export const Body_Web = styled(ellipsis)`
  ${BodyStyle}
  ${colors}
`;

export const BodyLink_Web = styled(ellipsis)`
  ${BodyLinkStyle}
  ${colors}
`;

export const BodyBold_Web = styled(ellipsis)`
  ${BodyBoldStyle}
  ${colors}
`;

export const BodySmall_Web = styled(ellipsis)`
  ${BodySmallStyle}
  ${colors}
`;

export const BodyBoldSmall_Web = styled(ellipsis)`
  ${BodyBoldSmallStyle}
  ${colors}
`;

export const H1_PDF = styled(ellipsis)`
  ${H1PDFStyle}
  ${colors}
`;

export const H2_PDF = styled(ellipsis)`
  ${H2PDFStyle}
  ${colors}
`;

export const Body_PDF = styled(ellipsis)`
  ${BodyPDFStyle}
  ${colors}
`;

export const BodyBold_PDF = styled(ellipsis)`
  ${BodyBoldPDFStyle}
  ${colors}
`;
