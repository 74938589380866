<template>
  <div style="z-index: 99">
    <div class="btn-help-desk">
      <div
        class="icon-arrow-help-desk cursor-pointer"
        @click="isClickDropUp = !isClickDropUp"
      >
        <i
          class="fa-solid fa-chevron-down"
          :class="{ rotated: isClickDropUp }"
          style="color: var(--primary-black)"
        >
        </i>
      </div>
      <transition name="drop-up">
        <div class="card-body box-help-desk" v-if="isClickDropUp">
          <styled-button
            type="list"
            class="w-100"
            @on-click="$bvModal.show('modal-steps-for-use')"
            v-if="
              $store.state?.main_company_role?.name == 'Admin' && showNavigate
            "
          >
            {{ $t("BTN_NAVIGATION") }}
          </styled-button>

          <styled-button type="list" class="w-100" @on-click="openManual">
            {{ $t("BTN_DOWNLOAD_MANUAL") }}
          </styled-button>
        </div>
      </transition>
    </div>
    <aside id="aside" :class="`${is_expanded ? 'is-expanded' : ''}`">
      <div class="menu">
        <div
          :class="` ${
            is_expanded ? 'container-sidebar' : 'container-sidebar-collapse'
          }`"
        >
          <a-tooltip placement="right">
            <template #title>
              <typo color="#fff">
                {{ organization ? organization.name_en : "" }}
              </typo>
            </template>
            <div
              class="button d-flex mt-2 px-2 align-items-center profile header-hover"
              :class="{
                'cursor-pointer': $store.state.admin_type == 1,
                'profile-card': is_expanded,
              }"
            >
              <div>
                <b-avatar variant="info" rounded :src="organization.file_url" />
              </div>
              <div class="select-box" v-if="is_expanded">
                <div
                  tabindex="1"
                  :class="`${is_dropdown ? 'is_dropdown' : ''}`"
                  v-click-outside="showDropdown"
                  @click="isDropdown()"
                >
                  <typo
                    type="body_web"
                    style="width: 155px; padding-left: 0.5rem"
                    class="text-left"
                    ellipsis
                    v-if="organization && organization.name_en"
                  >
                    {{ organization ? organization.name_en : "" }}
                  </typo>
                  <b-skeleton
                    v-else
                    style="
                      margin-left: 7px;
                      margin-right: 10px;
                      height: 1rem;
                      width: 155px;
                    "
                  />
                </div>
              </div>
            </div>
          </a-tooltip>
        </div>

        <!-- menu -->

        <div
          :class="` ${
            is_expanded ? 'container-sidebar' : 'container-sidebar-collapse'
          }`"
        >
          <router-link
            v-if="
              permissions &&
              permissions.overview &&
              permissions.overview.access == true
            "
            to="/overview-dashboard"
            class="button d-flex px-2 mt-2 underline-none"
            :class="[
              $route.fullPath === '/overview-dashboard' ||
              $route.fullPath === '/insight-dashboard'
                ? 'active-child'
                : '',
            ]"
            active-class="active"
            aria-expanded="false"
          >
            <div class="d-flex w-100">
              <span class="tooltip-info-menu">
                <div
                  style="margin-right: 10px"
                  class="d-flex align-items-center"
                  :style="`padding-left: ${
                    ($route.fullPath === '/overview-dashboard' ||
                      $route.fullPath === '/insight-dashboard') &&
                    !is_expanded
                      ? '6px'
                      : '10px'
                  };`"
                >
                  <analytics-icon />
                  <div
                    class="active-selected-box"
                    v-if="
                      ($route.fullPath === '/overview-dashboard' ||
                        $route.fullPath === '/insight-dashboard') &&
                      !is_expanded
                    "
                  />
                </div>
                <span v-if="!is_expanded" class="tooltip-text-info-menu">
                  <div class="tooltip-list">{{ $t("BTN_DASHBOARD") }}</div>
                </span>
              </span>
              <typo
                ellipsis
                type="body_web"
                class="text text-left"
                style="width: 155px"
              >
                {{ $t("BTN_DASHBOARD") }}
              </typo>
              <div
                class="active-selected-box"
                v-if="
                  $route.fullPath === '/overview-dashboard' ||
                  $route.fullPath === '/insight-dashboard'
                "
              />
            </div>
          </router-link>

          <div
            v-if="
              permissions &&
              permissions.setting &&
              permissions.setting.access == true
            "
            @click="expandSetting"
          >
            <router-link
              to="#"
              class="button sub-menu d-flex px-2 mt-2 underline-none dropdown"
              :class="[
                `${is_expanded ? 'is-expanded' : ''}`,
                `${
                  isBgActiveSubmenuSetting()
                    ? 'bg-active-icon-submenu'
                    : 'bg-transparent'
                }`,
              ]"
              active-class="active"
              data-bs-toggle="collapse"
              :data-bs-target="'#setting'"
              :aria-expanded="isOptionSetting ? true : false"
            >
              <span class="tooltip-info-menu">
                <div
                  style="margin-right: 10px"
                  class="d-flex align-items-center"
                  :style="`padding-left: ${
                    ($route.fullPath === '/organization-setting/organization' ||
                      $route.fullPath === '/organization-setting/building' ||
                      $route.fullPath === '/organization-setting/branch' ||
                      $route.fullPath === '/organization-setting/department' ||
                      $route.fullPath ===
                        '/organization-setting/setting-fiscal-year' ||
                      $route.fullPath === '/manage-activities' ||
                      $route.fullPath ===
                        '/manage-user-accounts/manage-users' ||
                      $route.fullPath === '/manage-user-accounts/assign-task' ||
                      $route.fullPath ===
                        '/manage-user-accounts/manage-auditors' ||
                      $route.fullPath ===
                        '/manage-user-accounts/manage-role-permission') &&
                    !is_expanded
                      ? '6px'
                      : '10px'
                  };`"
                >
                  <setting-icon />
                  <div
                    class="active-selected-box"
                    v-if="
                      ($route.fullPath ===
                        '/organization-setting/organization' ||
                        $route.fullPath === '/organization-setting/building' ||
                        $route.fullPath === '/organization-setting/branch' ||
                        $route.fullPath ===
                          '/organization-setting/department' ||
                        $route.fullPath ===
                          '/organization-setting/setting-fiscal-year' ||
                        $route.fullPath === '/manage-activities' ||
                        $route.fullPath ===
                          '/manage-user-accounts/manage-users' ||
                        $route.fullPath ===
                          '/manage-user-accounts/assign-task' ||
                        $route.fullPath ===
                          '/manage-user-accounts/manage-auditors' ||
                        $route.fullPath ===
                          '/manage-user-accounts/manage-role-permission') &&
                      !is_expanded
                    "
                  />
                </div>

                <!-- Tooltip menu -->
                <div v-if="!is_expanded" class="dropdown-content">
                  <div
                    style="border-radius: 8px; background: var(--primary-white)"
                  >
                    <a
                      class="d-flex"
                      :class="[
                        isActiveTooltipMenuOrganization()
                          ? 'bg-active-icon-submenu-tooltip'
                          : 'bg-inactive-icon-submenu-tooltip',
                      ]"
                      @click="toCompanyPage()"
                      v-if="
                        permissions &&
                        permissions.organization_structure &&
                        permissions.organization_structure.access == true
                      "
                    >
                      <div style="margin-right: 10px">
                        <status-circle-icon />
                      </div>
                      {{ $t("BTN_SETTING_ORGANIZATION") }}
                    </a>
                    <a
                      class="d-flex"
                      :class="[
                        !is_expanded &&
                        $route.fullPath.includes('/manage-activities')
                          ? 'bg-active-icon-submenu-tooltip'
                          : 'bg-inactive-icon-submenu-tooltip',
                      ]"
                      @click="toActivityList()"
                      v-if="
                        permissions &&
                        permissions.measure_list &&
                        permissions.measure_list.access == true
                      "
                    >
                      <div style="margin-right: 10px">
                        <status-circle-icon />
                      </div>
                      {{ $t("TXT_ACTIVITY_INVENTORY") }}
                    </a>
                    <a
                      class="d-flex"
                      :class="[
                        !is_expanded && $route.fullPath.includes('manage-users')
                          ? 'bg-active-icon-submenu-tooltip'
                          : 'bg-inactive-icon-submenu-tooltip',
                      ]"
                      @click="toUserMember()"
                      v-if="
                        permissions &&
                        permissions.user_account &&
                        permissions.user_account.access == true
                      "
                    >
                      <div style="margin-right: 10px">
                        <status-circle-icon />
                      </div>
                      {{ $t("BTN_MANAGE_USER_ACCOUNTS") }}
                    </a>
                  </div>
                </div>
              </span>

              <div class="d-flex w-100 align-items-baseline">
                <typo
                  ellipsis
                  type="body_web"
                  class="text"
                  style="width: 155px"
                >
                  {{ $t("BTN_SETTING_MAIN") }}
                </typo>
              </div>

              <span
                v-if="is_expanded"
                class="ms-auto"
                :class="`${
                  isOptionSetting ? 'isOptionSetting' : 'noOptionSetting'
                }`"
              >
                <i
                  class="fa-solid fa-chevron-right"
                  style="color: var(--primary-black)"
                >
                </i>
              </span>
            </router-link>
          </div>

          <!-- collapse setting menu -->
          <div
            v-if="is_expanded"
            id="setting"
            :class="[isOptionSetting ? 'collapse show' : 'collapsing']"
          >
            <div class="list-unstyled">
              <router-link
                v-if="
                  permissions &&
                  permissions.organization_structure &&
                  permissions.organization_structure.access == true
                "
                to="/organization-setting/organization"
                class="button list-menu d-flex px-3 mt-2 underline-none"
                :class="[
                  $route.fullPath === '/organization-setting/organization' ||
                  $route.fullPath === '/organization-setting/building' ||
                  $route.fullPath === '/organization-setting/branch' ||
                  $route.fullPath === '/organization-setting/department' ||
                  $route.fullPath ===
                    '/organization-setting/setting-fiscal-year'
                    ? 'active-child'
                    : '',
                ]"
                active-class="active"
              >
                <div class="d-flex w-100" style="padding-left: 1rem">
                  <div style="margin-left: 6px; margin-right: 10px">
                    <status-circle-icon />
                  </div>
                  <typo
                    ellipsis
                    type="body_web"
                    class="text text-left"
                    style="width: 155px"
                  >
                    {{ $t("BTN_SETTING_ORGANIZATION") }}
                  </typo>
                  <div
                    class="active-selected-box"
                    v-if="
                      $route.fullPath ===
                        '/organization-setting/organization' ||
                      $route.fullPath === '/organization-setting/building' ||
                      $route.fullPath === '/organization-setting/branch' ||
                      $route.fullPath === '/organization-setting/department' ||
                      $route.fullPath ===
                        '/organization-setting/setting-fiscal-year'
                    "
                  />
                </div>
              </router-link>
              <router-link
                v-if="
                  permissions &&
                  permissions.measure_list &&
                  permissions.measure_list.access == true
                "
                to="/manage-activities"
                class="button list-menu d-flex px-3 mt-2 underline-none"
                active-class="active"
              >
                <div class="d-flex w-100" style="padding-left: 1rem">
                  <div style="margin-left: 6px; margin-right: 10px">
                    <status-circle-icon />
                  </div>
                  <typo
                    ellipsis
                    type="body_web"
                    class="text text-left"
                    style="width: 155px"
                  >
                    {{ $t("TXT_ACTIVITY_INVENTORY") }}
                  </typo>
                  <div
                    class="active-selected-box"
                    v-if="$route.fullPath.includes('/manage-activities')"
                  />
                </div>
              </router-link>
              <router-link
                v-if="
                  permissions &&
                  permissions.user_account &&
                  permissions.user_account.access == true
                "
                to="/manage-user-accounts/manage-users"
                class="button list-menu d-flex px-3 mt-2 underline-none"
                active-class="active"
                :class="[
                  $route.fullPath === '/manage-user-accounts/manage-users' ||
                  $route.fullPath ===
                    '/manage-user-accounts/manage-role-permission' ||
                  $route.fullPath === '/manage-user-accounts/assign-task' ||
                  $route.fullPath === '/manage-user-accounts/manage-auditors'
                    ? 'active-child'
                    : '',
                ]"
              >
                <div class="d-flex w-100" style="padding-left: 1rem">
                  <div style="margin-left: 6px; margin-right: 10px">
                    <status-circle-icon />
                  </div>
                  <typo
                    ellipsis
                    type="body_web"
                    class="text text-left"
                    style="width: 155px"
                  >
                    {{ $t("BTN_MANAGE_USER_ACCOUNTS") }}
                  </typo>
                  <div
                    class="active-selected-box"
                    v-if="
                      $route.fullPath ===
                        '/manage-user-accounts/manage-users' ||
                      $route.fullPath ===
                        '/manage-user-accounts/manage-role-permission' ||
                      $route.fullPath === '/manage-user-accounts/assign-task' ||
                      $route.fullPath ===
                        '/manage-user-accounts/manage-auditors'
                    "
                  />
                </div>
              </router-link>
            </div>
          </div>

          <router-link
            v-if="
              permissions &&
              permissions.filling_out &&
              permissions.filling_out.access == true
            "
            to="/measurement-list"
            class="button d-flex px-2 mt-2 underline-none"
            active-class="active"
            aria-expanded="false"
            style="display: flex; place-items: center"
            :class="[
              $route.fullPath.includes('/measurement-list')
                ? 'active-child'
                : '',
            ]"
          >
            <div class="d-flex w-100">
              <span class="tooltip-info-menu">
                <div
                  style="margin-right: 10px"
                  class="d-flex align-items-center"
                  :style="`padding-left: ${
                    $route.fullPath.includes('/measurement-list') &&
                    !is_expanded
                      ? '6px'
                      : '10px'
                  };`"
                >
                  <memo-note-icon />
                  <div
                    class="active-selected-box"
                    v-if="
                      $route.fullPath.includes('/measurement-list') &&
                      !is_expanded
                    "
                  />
                </div>
                <span v-if="!is_expanded" class="tooltip-text-info-menu">
                  <div class="tooltip-list">
                    {{ $t("BC_FILLIN_MEASURE") }}
                  </div>
                </span>
              </span>
              <typo
                ellipsis
                type="body_web"
                class="text text-left"
                style="width: 155px"
              >
                {{ $t("BC_FILLIN_MEASURE") }}
              </typo>
              <div
                class="active-selected-box"
                v-if="$route.fullPath.includes('/measurement-list')"
              />
            </div>
          </router-link>

          <router-link
            v-if="
              permissions &&
              permissions.application &&
              permissions.application.access == true
            "
            to="/approval"
            class="button d-flex px-2 mt-2 underline-none"
            active-class="active"
            aria-expanded="false"
            style="display: flex; place-items: center"
          >
            <div class="d-flex w-100">
              <span class="tooltip-info-menu">
                <div
                  style="margin-right: 10px"
                  class="d-flex align-items-center"
                  :style="`padding-left: ${
                    $route.fullPath === '/approval' && !is_expanded
                      ? '6px'
                      : '10px'
                  };`"
                >
                  <mark-chat-icon />
                  <div
                    class="active-selected-box"
                    v-if="$route.fullPath === '/approval' && !is_expanded"
                  />
                </div>
                <span v-if="!is_expanded" class="tooltip-text-info-menu">
                  <div class="tooltip-list">
                    {{ $t("BTN_APPROVE_LISTS") }}
                  </div>
                </span>
              </span>
              <typo
                ellipsis
                type="body_web"
                class="text text-left"
                style="width: 155px"
              >
                {{ $t("BTN_APPROVE_LISTS") }}
              </typo>
              <div
                class="active-selected-box"
                v-if="$route.fullPath === '/approval'"
              />
            </div>
          </router-link>

          <div
            v-if="
              permissions &&
              permissions.custom_ef &&
              permissions.custom_ef.access == true
            "
            @click="expandEF"
          >
            <router-link
              to="#"
              class="button sub-menu d-flex px-2 mt-2 underline-none dropdown"
              :class="[
                `${is_expanded ? 'is-expanded' : ''}`,
                `${
                  isActiveSubmenuEF()
                    ? 'bg-active-icon-submenu'
                    : 'bg-transparent'
                }`,
              ]"
              active-class="active"
              data-bs-toggle="collapse"
              :data-bs-target="'#ef'"
              :aria-expanded="isOptionEF ? true : false"
            >
              <span class="tooltip-info-menu">
                <div
                  style="margin-right: 10px"
                  class="d-flex align-items-center"
                  :style="`padding-left: ${
                    ($route.fullPath === '/mapping-emission-factor' ||
                      $route.fullPath === '/custom-emission-factor') &&
                    !is_expanded
                      ? '6px'
                      : '10px'
                  };`"
                >
                  <cloud-icon />
                  <div
                    class="active-selected-box"
                    v-if="
                      ($route.fullPath === '/mapping-emission-factor' ||
                        $route.fullPath === '/custom-emission-factor') &&
                      !is_expanded
                    "
                  />
                </div>
                <div v-if="!is_expanded" class="dropdown-content">
                  <a
                    class="d-flex"
                    :class="[
                      !is_expanded &&
                      $route.fullPath == '/mapping-emission-factor'
                        ? 'bg-active-icon-submenu-tooltip'
                        : 'bg-inactive-icon-submenu-tooltip',
                    ]"
                    @click="toMappingEf()"
                    v-if="
                      permissions &&
                      permissions.custom_ef &&
                      permissions.custom_ef.access == true
                    "
                  >
                    <div style="margin-right: 10px">
                      <status-circle-icon />
                    </div>
                    Mapping EF
                  </a>

                  <a
                    class="d-flex"
                    :class="[
                      !is_expanded &&
                      $route.fullPath == '/custom-emission-factor'
                        ? 'bg-active-icon-submenu-tooltip'
                        : 'bg-inactive-icon-submenu-tooltip',
                    ]"
                    @click="toCustomEf()"
                    v-if="
                      permissions &&
                      permissions.custom_ef &&
                      permissions.custom_ef.access == true
                    "
                  >
                    <div style="margin-right: 10px">
                      <status-circle-icon />
                    </div>
                    {{ $t("LB_CUSTOM_EF") }}
                  </a>
                </div>
              </span>

              <div class="d-flex w-100 align-items-baseline">
                <typo
                  ellipsis
                  class="text"
                  type="body_web"
                  style="width: 155px"
                >
                  Emission Factor (EF)
                </typo>
              </div>

              <span
                v-if="is_expanded"
                class="ms-auto"
                :class="`${isOptionEF ? 'isOptionSetting' : 'noOptionSetting'}`"
              >
                <i
                  class="fa-solid fa-chevron-right"
                  style="color: var(--primary-black)"
                >
                </i>
              </span>
            </router-link>
          </div>

          <!-- collapse ef menu -->
          <div
            v-if="is_expanded"
            id="ef"
            :class="[isOptionEF ? 'collapse show' : 'collapsing']"
          >
            <div class="list-unstyled">
              <router-link
                v-if="
                  permissions &&
                  permissions.custom_ef &&
                  permissions.custom_ef.access == true
                "
                to="/mapping-emission-factor"
                class="button list-menu d-flex px-3 mt-2 underline-none"
                :class="[
                  $route.fullPath === '/mapping-emission-factor'
                    ? 'active-child'
                    : '',
                ]"
                active-class="active"
              >
                <div class="d-flex w-100" style="padding-left: 1rem">
                  <div style="margin-left: 6px; margin-right: 10px">
                    <status-circle-icon />
                  </div>
                  <typo
                    ellipsis
                    type="body_web"
                    class="text text-left"
                    style="width: 155px"
                  >
                    Mapping EF
                  </typo>
                  <div
                    class="active-selected-box"
                    v-if="$route.fullPath === '/mapping-emission-factor'"
                  />
                </div>
              </router-link>
              <router-link
                v-if="
                  permissions &&
                  permissions.custom_ef &&
                  permissions.custom_ef.access == true
                "
                to="/custom-emission-factor"
                class="button list-menu d-flex px-3 mt-2 underline-none"
                active-class="active"
              >
                <div class="d-flex w-100" style="padding-left: 1rem">
                  <div style="margin-left: 6px; margin-right: 10px">
                    <status-circle-icon />
                  </div>
                  <typo
                    ellipsis
                    type="body_web"
                    class="text text-left"
                    style="width: 155px"
                  >
                    {{ $t("LB_CUSTOM_EF") }}
                  </typo>
                  <div
                    class="active-selected-box"
                    v-if="$route.fullPath === '/custom-emission-factor'"
                  />
                </div>
              </router-link>
            </div>
          </div>

          <router-link
            v-if="
              permissions &&
              permissions.audit &&
              permissions.audit.access == true
            "
            to="/verify-information"
            class="button sub-menu d-flex px-2 mt-2 underline-none"
            active-class="active"
            aria-expanded="false"
            style="display: flex; place-items: center"
            :class="[
              $route.fullPath.includes('/verify-information')
                ? 'active-child'
                : '',
            ]"
          >
            <div class="d-flex w-100">
              <span class="tooltip-info-menu">
                <div
                  style="margin-right: 10px"
                  class="d-flex align-items-center"
                  :style="`padding-left: ${
                    $route.fullPath.includes('/verify-information') &&
                    !is_expanded
                      ? '6px'
                      : '10px'
                  };`"
                >
                  <report-icon />
                  <div
                    class="active-selected-box"
                    v-if="
                      $route.fullPath.includes('/verify-information') &&
                      !is_expanded
                    "
                  />
                </div>
                <span v-if="!is_expanded" class="tooltip-text-info-menu">
                  <div class="tooltip-list">
                    {{ $t("LB_VERIFY_INFORMATION") }}
                  </div>
                </span>
              </span>
              <typo
                ellipsis
                type="body_web"
                class="text text-left"
                style="width: 155px"
              >
                {{ $t("LB_VERIFY_INFORMATION") }}
              </typo>
              <div
                class="active-selected-box"
                v-if="$route.fullPath.includes('/verify-information')"
              />
            </div>
          </router-link>
        </div>
      </div>
      <div
        @click="toggleSidebar"
        style="height: inherit; margin-left: -15px; margin-right: -13px"
      />
      <div class="footer-area">
        <hr
          style="
            border: 1px solid var(--primary-black);
            margin-left: 16px;
            margin-right: 16px;
          "
          :hidden="!is_expanded"
        />
        <hr
          style="
            border: 1px solid var(--primary-black);
            margin-left: 10px;
            margin-right: 10px;
          "
          :hidden="is_expanded"
        />
        <div
          class="footer-menu"
          :class="`${
            is_expanded ? 'container-sidebar' : 'container-sidebar-collapse'
          }`"
        >
          <div class="footer-icon">
            <div class="collapse show" id="profile">
              <div class="list-unstyled">
                <div
                  class="button list-menu d-flex mt-2 underline-none cursor-pointer"
                  active-class="active"
                  :style="`width: ${!is_expanded ? '44px' : ''};`"
                  @click="openSettingProfile()"
                >
                  <div class="d-flex w-100">
                    <div
                      style="
                        padding-left: 2px;
                        margin-right: 10px;
                        padding-top: 3px;
                        padding-bottom: 3px;
                      "
                      class="d-flex align-items-center"
                    >
                      <avatar-icon
                        v-if="!main_company || !main_company.user.file"
                      />
                      <b-avatar
                        v-else
                        :src="main_company.user.file.file_url"
                        style="width: 32px; height: 32px; border-radius: 100%"
                      />
                    </div>
                    <div
                      v-if="is_expanded"
                      style="width: 150px"
                      class="align-content-center my-auto"
                    >
                      <typo
                        ellipsis
                        type="bold_web"
                        v-if="profile && profile.first_name"
                      >
                        {{ profile.first_name }} {{ profile.last_name }}
                      </typo>

                      <typo
                        type="body_web"
                        v-if="profile && !profile.first_name"
                      >
                        {{ profile ? profile.email : "" }}
                      </typo>
                      <b-skeleton v-if="!profile" style="height: 1rem" />
                      <typo type="body_web">
                        {{ profile ? profile.job_role : "" }}
                      </typo>
                    </div>
                  </div>
                </div>
                <div
                  v-if="$store.state.oauth_token == null"
                  class="button list-menu d-flex mt-2 underline-none cursor-pointer"
                  active-class="active"
                  :style="`width: ${!is_expanded ? '44px' : ''};`"
                  @click="openModalChangePassword()"
                >
                  <div class="d-flex w-100">
                    <div
                      style="padding-left: 10px; margin-right: 10px"
                      class="d-flex align-items-center"
                    >
                      <lock-open-icon />
                    </div>
                    <typo
                      ellipsis
                      type="body_web"
                      class="text text-left"
                      style="width: 155px"
                    >
                      {{ $t("TXT_CHANGE_PASSWORD") }}
                    </typo>
                  </div>
                </div>
                <div
                  class="button list-menu d-flex mt-2 underline-none cursor-pointer"
                  active-class="active"
                  :style="`width: ${!is_expanded ? '44px' : ''};`"
                  @click="openConfirmLogout()"
                >
                  <div class="d-flex w-100">
                    <div
                      style="padding-left: 10px; margin-right: 10px"
                      class="d-flex align-items-center"
                    >
                      <exit-icon />
                    </div>
                    <typo
                      ellipsis
                      type="body_web"
                      class="text text-left"
                      style="width: 155px"
                    >
                      {{ $t("BTN_SIGN_OUT") }}
                    </typo>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </aside>
    <ModalFormProfile
      v-if="count_double_login != 1"
      :main-company="user_data"
      @get-member="getMainCompany"
      @get-user="getUser"
      @reset-profile="resetProfile"
    />
    <ModalChangePassword
      v-if="count_double_login != 1"
      :user-type="profile ? profile.type : 1"
      @logout="$globalLogout"
    />
    <ModalFormPassword
      v-if="count_double_login != 1"
      :user-type="profile ? profile.type : 1"
      :force-password="profile && profile.is_force_password_reset"
    />
    <ModalStepsForUse v-if="count_double_login != 1" />
    <ModalTermAndCondition
      :is-show-btn-submit="true"
      :hide-close-btn="true"
      @logoutWithTerm="logoutWithTerm"
      @acceptTerm="acceptTerm"
    />
    <ModalWarning
      :id="modal_warning_id"
      :title="modal_warning_title"
      :content="modal_warning_content"
      :btnSubmitName="modal_warning_btn_name"
      :submitFunction="submitFunction"
    />
  </div>
</template>

<script>
import { httpClient } from "@/services/axiosInstance.js";
import { apiURL } from "@/configs/serverConfig";
import axios from "axios";
import { mapGetters } from "vuex";
import moment from "moment";
import Cookies from "js-cookie";
import helper from "@/mixins/helper";

import ModalStepsForUse from "@/components/Modal/ModalStepsForUse.vue";
import ModalChangePassword from "@/components/Modal/settingprofile/ModalChangePassword.vue";
import ModalFormProfile from "@/components/Sidebar/modals/ModalFormProfile.vue";
import ModalFormPassword from "@/components/Sidebar/modals/ModalFormPassword.vue";
import ModalWarning from "@/components/Modal/Alert/ModalWarning.vue";
import ModalTermAndCondition from "@/components/Modal/ModalTermAndConditionSET";

export default {
  props: ["toggleMenu"],
  components: {
    ModalChangePassword,
    ModalStepsForUse,
    ModalFormProfile,
    ModalFormPassword,
    ModalWarning,
    ModalTermAndCondition,
  },
  mixins: [helper],
  watch: {
    toggleMenu: "setExpanded",
    isLoggedIn(newVal) {
      if (newVal) {
        this.loadFreshdeskWidget(); // โหลดวิดเจ็ตเมื่อผู้ใช้ล็อกอิน
      } else {
        this.hideFreshdeskWidget(); // ซ่อนวิดเจ็ตเมื่อผู้ใช้ออกจากระบบ
      }
    },
  },
  data() {
    return {
      is_expanded: this.$store.state.menu.is_expanded,
      isOptionSetting: this.$store.state.menu.is_option_setting,
      isOptionEF: this.$store.state.menu.is_option_ef,
      isOptionProfile: false,
      profile: null,
      main_company: null,
      formUpload: null,
      responseDataUpload: null,
      isApprove: false,
      roleAction: this.$store.state.role_action,
      branch: null,
      isLoadingMenu: false,
      is_dropdown: false,
      primaryMainCompany: null,
      user_data: null,
      countDropdownCompany: 0,
      companies: null,
      organization: {},
      showNavigate: true,
      state_oauth: null,
      modal_warning_id: null,
      modal_warning_title: null,
      modal_warning_content: null,
      modal_warning_btn_name: null,
      count_double_login:
        this.$store &&
        this.$store.state &&
        this.$store.state.count_double_login,
      isClickDropUp: false,
      handleCompletedStep: false,
    };
  },
  computed: {
    ...mapGetters({
      permissions: "getPermissions",
      setupType: "getSetupType",
      auditorType: "getAuditorType",
      loginType: "getLoginType",
    }),
    isLoggedIn() {
      return !!this.$store.state.token; // แปลง token เป็น Boolean
    },
  },
  async mounted() {
    if (!Cookies.get("session")) {
      await this.$globalLogout();
      this.redirectOnLogout();
    }
    await this.getUser();
    if (this.$store.state.count_double_login == 1) {
      this.modalWarningDoubleLogin();
    } else {
      // await this.getUser();
      // await this.getMainCompany();
      await this.getDataCompanies();
      if (this.profile && this.profile.is_force_password_reset) {
        this.openModalChangePassword();
      }
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 1024) {
        this.is_expanded = false;
        localStorage.setItem("is_expanded", this.is_expanded);
        if (!this.is_expanded) {
          this.isOptionSetting = false;
          localStorage.setItem("is_option_setting", this.isOptionSetting);
        }
      }
    });
    if (this.$store.state.token) {
      this.loadFreshdeskWidget();
    } else {
      console.error("User not logged in, widget not loaded.");
      this.hideFreshdeskWidget();
    }
  },
  methods: {
    async logoutWithTerm() {
      await this.$globalLogout();
      this.redirectOnLogout();
    },
    async acceptTerm(is_accept_term) {
      const _data = {
        is_terms_accepted: is_accept_term,
      };
      try {
        const accept_term = await httpClient.put(`${apiURL}/user/terms`, _data);
        if (accept_term.status == 200 && is_accept_term) {
          this.getUser();
          this.$toast.success({
            component: "styled-toast",
            props: {
              type: "success",
              message: this.$t("TXT_SAVE_DONE"),
            },
          });
          this.$bvModal.hide("modal-term");
        }
      } catch (err) {
        if (err) {
          this.$toast.error({
            component: "styled-toast",
            props: {
              type: "error",
              message: err.response.data.message,
            },
          });
        }
      }
    },
    loadFreshdeskWidget() {
      this.setFreshdeskSettings();
      this.loadScript("https://widget.freshworks.com/widgets/157000000440.js");

      const widgetElement = document.querySelector("#freshworks-container");
      // console.log("widgetElement", widgetElement);
      if (widgetElement?.style.display == "none") {
        widgetElement.style.display = "block";
      }
    },

    // Helper function to set Freshdesk widget settings
    setFreshdeskSettings() {
      window.fwSettings = { widget_id: 157000000440 };
      if (typeof window.FreshworksWidget !== "function") {
        window.FreshworksWidget = function () {
          window.FreshworksWidget.q.push(arguments);
        };
        window.FreshworksWidget.q = [];
      }
    },
    // Helper function to load a script dynamically
    loadScript(src) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = src;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    },
    hideFreshdeskWidget() {
      if (typeof window.FreshworksWidget === "function") {
        window.FreshworksWidget("close"); // Hide the widget
        // location.reload();
      }
      const widgetElement = document.querySelector("#freshworks-container");
      if (widgetElement) {
        widgetElement.style.display = "none";
      }
    },
    openManual() {
      if (this.$store.state.auditor_type) {
        window.open(
          "https://set-carbon-uat.s3.ap-southeast-1.amazonaws.com/mannual/SETCarbon_93142414-5e5d-4572-8_คู่มือการใช้งานSETCarbonผู้ทวนสอบ.pdf",
          "_blank"
        );
      } else {
        window.open(
          "https://set-carbon-uat.s3.ap-southeast-1.amazonaws.com/mannual/SETCarbon_82941478-f3a4-4bb7-a_คู่มือการใช้งานSETCarbonผู้ใช้งานทั่วไป.pdf",
          "_blank"
        );
      }
    },
    generateState() {
      this.state_oauth = Math.random().toString(36).substring(7);
    },
    setExpanded(is_expanded) {
      this.is_expanded = is_expanded;
    },
    async getUser() {
      if (this.$store.state.token !== "" && Cookies.get("session") != null) {
        try {
          const response = await httpClient.get(`${apiURL}/user`, {
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
              Authorization: `Bearer ${this.$store.state.token}`,
              SessionID: Cookies.get("session"),
            },
          });

          this.branch = response.data.primary_main_company;
          this.profile = response.data;
          this.$store.dispatch("setUserData", response.data);
          // Modal logic
          if (response.data && !response.data.is_terms_accepted) {
            this.$bvModal.show("modal-term");
          } else {
            await this.getMainCompany();
          }
        } catch (err) {
          if (err) {
            this.$toast.error({
              component: "styled-toast",
              props: {
                type: "error",
                message: err.response.data.message,
              },
            });
          }
        }
      } else {
        if (this.$router.currentRoute.path.includes("mobile")) {
          this.$router.push({ name: "Signin CPF" });
        } else {
          this.$router.push("/");
        }
      }
    },
    showDropdown() {
      this.is_dropdown = false;
    },

    async getDataCompanies() {
      const url = `${apiURL}/company?size=20&page=1&sort=id ASC`;

      try {
        const companies = await httpClient.get(url);
        if (companies.data.entities && companies.data.entities.length > 0) {
          companies.data.entities.forEach((list) => {
            if (list.is_default) {
              this.organization = {
                ...list,
                file_url: list.file ? list.file.file_url : null,
              };
            }
          });
          this.companies = companies.data.entities;
        } else {
          this.companies = [];
        }
      } catch (err) {
        if (err) {
          this.$toast.error({
            component: "styled-toast",
            props: {
              type: "error",
              message: err.response.data.message,
            },
          });
        }
      }
    },
    // Generic function to toggle option state and update the store
    toggleOption(optionName) {
      this.is_expanded = true;
      localStorage.setItem("is_expanded", true);
      const toggleOptionKey = `is${optionName}`;
      this[toggleOptionKey] = !this[toggleOptionKey];
      if (optionName === "OptionSetting") {
        this.$store.dispatch("menu/setIsOptionSetting", this[toggleOptionKey]);
      } else if (optionName === "OptionEF") {
        this.$store.dispatch("menu/setIsOptionEF", this[toggleOptionKey]);
      }
      this.$store.dispatch(
        "menu/setStatusCloseMenu",
        !this.$store.state.menu.is_close_menu
      );
    },

    expandSetting() {
      this.toggleOption("OptionSetting");
    },
    expandEF() {
      this.toggleOption("OptionEF");
    },
    expandProfile() {
      this.toggleOption("OptionProfile");
    },
    openConfirmLogout() {
      this.modal_warning_id = "modal-logout";
      this.modal_warning_title = this.$t("TXT_SIGN_OUT");
      this.modal_warning_content = this.$t("TXT_CONFIRM_LOG_OUT");
      this.modal_warning_btn_name = this.$t("BTN_SIGN_OUT");
      this.$nextTick(() => {
        this.$bvModal.show("modal-logout");
      });
    },

    // async logout() {
    //   const url = `${apiURL}/user/logout`;

    //   try {
    //     const logout = await httpClient.post(url);
    //     if (logout.status == 200) {
    //       localStorage.clear();
    //       Cookies.remove("session");
    //       this.$store.commit("setToken", "");
    //       this.$store.commit("setRefreshToken", "");
    //       this.$store.commit("setRoleAction", null);
    //       this.$nextTick(() => {
    //         if (
    //           this.$store.state.user_data.type == 2 ||
    //           this.$store.state.user_data.type == 3
    //         ) {
    //           //auditor
    //           this.$router.push("/login-auditor");
    //         } else {
    //           this.$router.push("/");
    //         }
    //         if (this.$store.state.oauth_token) {
    //           this.$store.commit("setOAuthToken", null);
    //           this.$store.commit("setOAuthRefreshToken", null);
    //           let logoutSession = window.open(
    //             `${process.env.VUE_APP_SETLINK_URL}/authen/logout`,
    //             "_blank"
    //           );
    //           const myTimeout = setTimeout(() => {
    //             logoutSession.close();
    //             clearTimeout(myTimeout);
    //           }, 300);
    //         }
    //       });
    //     }
    //   } catch (err) {
    //     if (err) {
    //       this.$toast.error({
    //         component: "styled-toast",
    //         props: {
    //           type: "error",
    //           message: err.response.data.message,
    //         },
    //       });
    //     }
    //   }
    // },
    toggleSidebar() {
      this.is_expanded = !this.is_expanded;
      this.$store.dispatch("menu/setIsExpanded", this.is_expanded);
      const isMenuClosed = !this.$store.state.menu.is_close_menu;
      this.$store.dispatch("menu/setStatusCloseMenu", isMenuClosed);
      if (!this.is_expanded) {
        this.resetMenuOptions();
      } else {
        this.updateMenuOptions();
      }
    },
    resetMenuOptions() {
      this.isOptionSetting = false;
      this.isOptionEF = false;
    },
    updateMenuOptions() {
      this.isOptionSetting = this.$store.state.menu.is_option_setting;
      this.isOptionEF = this.$store.state.menu.is_option_ef;
      this.$store.dispatch("menu/setIsOptionSetting", this.isOptionSetting);
      this.$store.dispatch("menu/setIsOptionEf", this.isOptionEF);
    },

    async getMainCompany() {
      this.isLoadingMenu = false;
      const url = `${apiURL}/member`;

      try {
        const main_company = await httpClient.get(url);
        for (let key in main_company.data.page_permission) {
          if (main_company.data.page_permission[key].access === false) {
            this.showNavigate = false;
          }
        }

        this.handleCompletedStep =
          main_company.data.main_company.is_navigation_opened;

        // force password
        if (this.profile && this.profile.is_force_password_reset) {
          this.openModalChangePassword();
        } else {
          // show steps for use
          if (!this.handleCompletedStep && this.showNavigate) {
            this.$bvModal.show("modal-steps-for-use");
          }
        }

        this.primaryMainCompany = main_company.data.user
          ? main_company.data.user.primary_main_company
          : null;
        this.main_company = main_company.data;

        this.$store.commit("setMainCompany", this.main_company);

        this.$store.dispatch("setCompanyId", main_company.data.main_company_id);
        this.$store.dispatch(
          "setCompanyName",
          main_company.data.main_company.name_th
        );
        if (main_company.data.main_company_role) {
          this.$store.dispatch(
            "setRoleId",
            main_company.data.main_company_role.id
          );
          this.$store.dispatch(
            "setRoleAction",
            main_company.data.main_company_role.group_roles
          );
        }

        if (main_company.data.main_company.file) {
          this.$store.dispatch(
            "setCompanyImage",
            main_company.data.main_company &&
              main_company.data.main_company.file
              ? main_company.data.main_company.file.file_url
              : ""
          );
        } else {
          this.$store.dispatch("setCompanyImage", null);
        }
        this.$store.dispatch(
          "setMainCompanyRole",
          main_company.data.main_company_role
        );
        this.$store.dispatch("setAdminType", main_company.data.type);
        this.$store.dispatch(
          "setUserType",
          main_company.data && main_company.data.user
            ? main_company.data.user.type
            : null
        );
        this.$store.dispatch(
          "setUpType",
          main_company.data.main_company.set_up_type
        );
        this.$store.dispatch("setAuditorType", main_company.data.auditor_type);
        this.$store.dispatch("setStatusActive", main_company.data.status);
        await this.$store.dispatch(
          "setPermissionLogin",
          main_company.data.page_permission
        );
        this.isLoadingMenu = true;
      } catch (err) {
        if (err) {
          this.$toast.error({
            component: "styled-toast",
            props: {
              type: "error",
              message: err.response.data.message,
            },
          });
        }
      }
    },

    async uploadImage(data) {
      this.formUpload = data;
      if (data.files) {
        const headers = {
          Accept: "application/json",
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${this.$store.state.token}`,
          "file-path": "company",
        };

        const url = `${apiURL}/file`;
        let formData = new FormData();
        formData.append("file", data.files);
        try {
          const uploadImage = await httpClient.post(url, formData, {
            headers: headers,
          });
          this.responseDataUpload = await uploadImage.data;
          await this.editProfile();
        } catch (err) {
          if (err) {
            this.$toast.error({
              component: "styled-toast",
              props: {
                type: "error",
                message: err.response.data.message,
              },
            });
          }
        }
      } else {
        this.editProfile();
      }
    },
    async editProfile() {
      const url = `${apiURL}/main_company`;
      const _data = {
        name_th: this.formUpload.company_th,
        name_en: this.formUpload.company,
        latitude: this.formUpload.latitude.toString(),
        longitude: this.formUpload.longitude.toString(),
        dashboard_setting: {
          start_date: moment(this.formUpload.startDate).format(
            "YYYY-MM-DDT00:00:00+07:00"
          ),
          end_date: moment(this.formUpload.endDate).format(
            "YYYY-MM-DDT23:59:59+07:00"
          ),
        },
        location_name: this.formUpload.location,
        file_id: this.responseDataUpload ? this.responseDataUpload.id : null,
        start_input_date: this.formUpload.start_input_date
          ? moment(this.formUpload.start_input_date).format()
          : null,
      };

      try {
        const updateProfile = await httpClient.put(url, _data);
        // console.log("updateProfile", updateProfile);
        if (updateProfile.status == 200) {
          this.$bvModal.hide("sidebar-form-organization");
          this.$toast.success({
            component: "styled-toast",
            props: {
              type: "success",
              message: this.$t("TXT_SAVE_DONE"),
            },
          });
          // this.getCompanyList();
        }
      } catch (err) {
        this.$bvModal.hide("modal-form-organization");
        if (err) {
          this.$toast.error({
            component: "styled-toast",
            props: {
              type: "error",
              message: err.response.data.message,
            },
          });
        }
      }
      this.getMainCompany();
    },
    // approveMenu() {
    //   this.isApprove = !this.isApprove;
    //   this.is_expanded = true;
    //   localStorage.setItem("is_expanded", this.is_expanded);
    // },
    // async setLangLogout() {
    //   const url = `${apiURL}/user/language`;

    //   const _data = {
    //     language: "en",
    //   };

    //   try {
    //     await httpClient.put(url, _data);
    //   } catch (err) {
    //     this.$toast.error({
    //       component: "styled-toast",
    //       props: {
    //         type: "error",
    //         message: err.response.data.message,
    //       },
    //     });
    //   }
    // },
    openSettingProfile() {
      this.user_data = this.main_company;
      this.$bvModal.show("modal-form-profile");
    },
    openModalChangePassword() {
      this.$bvModal.show("modal-form-password");
    },
    closeModalProfileCompany() {
      this.$bvModal.hide("modal-change-profile-company");
    },
    resetProfile() {
      this.user_data = null;
    },
    isDropdown() {
      if (this.countDropdownCompany > 0) {
        this.is_dropdown = !this.is_dropdown;
      }
    },
    isRouteActive(paths) {
      return !this.is_expanded && paths.includes(this.$route.fullPath);
    },
    isBgActiveSubmenuSetting() {
      const paths = [
        "/organization-setting/organization",
        "/organization-setting/branch",
        "/organization-setting/building",
        "/organization-setting/department",
        "/organization-setting/setting-fiscal-year",
        "/manage-user-accounts/special-variable",
        "/manage-user-accounts/manage-users",
        "/manage-activities",
        "/manage-user-accounts/manage-role-permission",
        "/manage-user-accounts/assign-task",
        "/manage-user-accounts/manage-auditors",
      ];
      return this.isRouteActive(paths);
    },
    isActiveSubmenuEF() {
      const paths = ["/mapping-emission-factor", "/custom-emission-factor"];
      return this.isRouteActive(paths);
    },
    isActiveTooltipMenuOrganization() {
      const paths = [
        "/organization-setting/organization",
        "/organization-setting/branch",
        "/organization-setting/building",
        "/organization-setting/department",
        "/organization-setting/setting-fiscal-year",
      ];
      return this.isRouteActive(paths);
    },
    navigateTo(path) {
      if (this.$route.fullPath !== path) {
        this.$router.push(path);
      }
    },
    toActivityList() {
      this.navigateTo("/manage-activities");
    },
    toCompanyPage() {
      this.navigateTo("/organization-setting/organization");
    },
    toSpecialVariable() {
      this.navigateTo("/special-variable");
    },
    toUserMember() {
      this.navigateTo("/manage-user-accounts/manage-users");
    },
    toCustomEf() {
      this.navigateTo("/custom-emission-factor");
    },
    toMappingEf() {
      this.navigateTo("/mapping-emission-factor");
    },
    async logoutImplicit() {
      // this.generateState();
      const url = `${process.env.VUE_APP_SETLINK_URL}/session/logout?id_token_hint=${this.$store.state.oauth_token}&post_logout_redirect_uri=${process.env.VUE_APP_URL}`;
      const header = {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.$store.state.oauth_token}`,
      };
      try {
        const logoutImplicit = await axios.get(url, { headers: header });
        if (logoutImplicit.status == 200) {
          this.$toast.success({
            component: "styled-toast",
            props: {
              type: "success",
              message: "Logout Implicit Success",
            },
          });
        }
      } catch (err) {
        if (err) {
          this.$toast.error({
            component: "styled-toast",
            props: {
              type: "error",
              message: err,
            },
          });
        }
      }
    },
    async submitFunction() {
      if(this.modal_warning_id == 'modal-logout') {
        await this.$globalLogout();
        this.redirectOnLogout();
      } else {
        localStorage.clear();
          Cookies.remove("session");
          this.$store.commit("setToken", "");
          this.$store.commit("setRefreshToken", "");
          this.$store.commit("setRoleAction", null);
          this.$nextTick(() => {
            if (this.$store.state.oauth_token) {
              this.$store.commit("setOAuthToken", null);
              this.$store.commit("setOAuthRefreshToken", null);
              let logoutSession = window.open(
                `${process.env.VUE_APP_SETLINK_URL}/authen/logout`,
                "_blank"
              );
              const myTimeout = setTimeout(() => {
                logoutSession.close();
                clearTimeout(myTimeout);
              }, 300);
            }
          });
        // await this.$globalLogout();
        this.redirectOnLogout();
      }
      // if (
      //   ["modal-logout", "modal-warning-double-login"].includes(
      //     this.modal_warning_id
      //   )
      // ) {
      //   localStorage.clear();
      //     Cookies.remove("session");
      //     this.$store.commit("setToken", "");
      //     this.$store.commit("setRefreshToken", "");
      //     this.$store.commit("setRoleAction", null);
      //     this.$nextTick(() => {
      //       if (this.$store.state.oauth_token) {
      //         this.$store.commit("setOAuthToken", null);
      //         this.$store.commit("setOAuthRefreshToken", null);
      //         let logoutSession = window.open(
      //           `${process.env.VUE_APP_SETLINK_URL}/authen/logout`,
      //           "_blank"
      //         );
      //         const myTimeout = setTimeout(() => {
      //           logoutSession.close();
      //           clearTimeout(myTimeout);
      //         }, 300);
      //       }
      //     });
      //   // await this.$globalLogout();
      //   this.redirectOnLogout();
      // }
    },
    // async refreshTokenOauth() {
    //   const _data = {
    //     client_id: "SETLink",
    //     client_secret: "password2",
    //     grant_type: "refresh_token",
    //     refresh_token: this.$store.state.oauth_refresh_token,
    //   };

    //   const header = {
    //     "Content-type": "application/x-www-form-urlencoded",
    //   };
    //   try {
    //     const loginSet = await axios.post(
    //       `${process.env.VUE_APP_SETLINK_URL}/authen/oauth/token`,
    //       _data,
    //       { headers: header }
    //     );

    //     if (loginSet.status == 200) {
    //       this.$store.dispatch("setOAuthToken", loginSet.data.access_token);
    //       this.$store.dispatch(
    //         "setOAuthRefreshToken",
    //         loginSet.data.refresh_token
    //       );
    //       this.$toast.success({
    //         component: "styled-toast",
    //         props: {
    //           type: "success",
    //           message: "Login successful",
    //         },
    //       });
    //     }
    //   } catch (error) {
    //     this.$toast.error({
    //       component: "styled-toast",
    //       props: {
    //         type: "error",
    //         message: error.message,
    //       },
    //     });
    //   }
    // },
    // async logoutImplicit2() {
    //   // this.generateState();
    //   const _data = {
    //     id_token_hint: this.$store.state.oauth_token,
    //     post_logout_redirect_uri: process.env.VUE_APP_URL,
    //   };
    //   const url = `${process.env.VUE_APP_SET_TOKEN_END_POINT}`;
    //   const header = {
    //     Accept: "*/*",
    //     "Content-Type": "application/x-www-form-urlencoded",
    //     Authorization: `Bearer ${this.$store.state.oauth_token}`,
    //   };
    //   try {
    //     const logoutImplicit = await axios.post(url, _data, {
    //       headers: header,
    //     });
    //     if (logoutImplicit.status == 200) {
    //       this.$toast.success({
    //         component: "styled-toast",
    //         props: {
    //           type: "success",
    //           message: "Logout Implicit Success",
    //         },
    //       });
    //     }
    //   } catch (err) {
    //     if (err) {
    //       this.$toast.error({
    //         component: "styled-toast",
    //         props: {
    //           type: "error",
    //           message: err,
    //         },
    //       });
    //     }
    //   }
    // },
    // async submitFunction() {
    //   if (this.modal_warning_id == "modal-logout") {
    //     await this.$globalLogout();
    //     this.redirectOnLogout();
    //   } else if (this.modal_warning_id == "modal-warning-double-login") {
    //     await this.$globalLogout();
    //     this.redirectOnLogout();
    //     // this.$store.commit("setToken", "");
    //     // this.$store.commit("setRefreshToken", "");
    //     // this.$store.commit("setRoleAction", null);

    //     // if (this.$store.state.oauth_token) {
    //     //   this.$store.commit("setOAuthToken", null);
    //     //   this.$store.commit("setOAuthRefreshToken", null);
    //     //   let logoutSession = window.open(
    //     //     `${process.env.VUE_APP_SETLINK_URL}/authen/logout`,
    //     //     "_blank"
    //     //   );
    //     //   const myTimeout = setTimeout(() => {
    //     //     logoutSession.close();
    //     //     clearTimeout(myTimeout);
    //     //   }, 300);

    //     //   const myTimeout2 = setTimeout(() => {
    //     //     this.$router.go();
    //     //     clearTimeout(myTimeout2);
    //     //   }, 500);
    //     // } else {
    //     //   this.$router.go();
    //     // }
    //   }
    // },
    async modalWarningDoubleLogin() {
      if (this.$store.state && this.$store.state.count_double_login == 1) {
        this.modal_warning_id = "modal-warning-double-login";
        this.modal_warning_title = this.$t("TXT_ABNORMALITY_LOGIN");
        this.modal_warning_content = this.$t("TXT_ABNORMALITY_LOGIN_DETAIL");
        this.modal_warning_btn_name = this.$t("BTN_CLOSE");
        this.$nextTick(() => {
          this.$bvModal.show("modal-warning-double-login");
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.box-help-desk {
  position: fixed;
  right: 10px;
  bottom: 80px;
  z-index: 99;
  border-radius: 8px;

  /* boxShadowSecondary */
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  max-width: 250px;
  padding: 10px;
}
.rotated {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.drop-up-enter-active,
.drop-up-leave-active {
  transition: transform 0.3s ease, opacity 0.3s ease;
}
.drop-up-enter-from {
  transform: translateY(20px);
  opacity: 0;
}
.drop-up-enter-to {
  transform: translateY(0);
  opacity: 1;
}
.drop-up-leave-from {
  transform: translateY(0);
  opacity: 1;
}
.drop-up-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.btn-help-desk {
  display: flex;
  bottom: 35px;
  z-index: 99;
  // rotate: 180deg;
  position: fixed;
  right: 10px;
  .icon-arrow-help-desk {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08),
      0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
}
.header-hover:hover {
  background-color: var(--tertiary-orange_10) !important;
}

.footer-area {
  margin: 0 -1rem;
  background: transparent;
}

.select-box {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  // font-family: "Open Sans", "Helvetica Neue", "Segoe UI", "Calibri", "Arial",
  //   sans-serif;
  font-size: 18px;
  color: #fff;
  .is_dropdown {
    position: relative;
    // box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
    cursor: pointer;
    outline: none;

    &:focus {
      & + .select-box__list {
        opacity: 1;

        animation-name: none;

        .select-box__option {
          cursor: pointer;
        }
      }

      .select-box__icon {
        transform: translateY(-50%) rotate(180deg);
        transition: 0.2s ease;
      }
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 20px;
    opacity: 0.5;
    transition: 0.2s ease;
  }

  &__value {
    display: flex;
  }

  &__input {
    display: none;

    &:checked + .select-box__input-text {
      display: block;
    }
  }

  &__input-text {
    display: none;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #353f4f;
  }

  &__list {
    position: absolute;
    width: 100%;
    padding: 0;
    list-style: none;
    opacity: 0;
    margin-top: 1rem;

    animation-name: HideList;
    animation-duration: 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: step-start;
    box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
  }

  &__option {
    display: block;
    padding: 15px;
    background-color: #353f4f;

    &:hover,
    &:focus {
      color: #fff;
      background: #414d5f;
    }
  }
  .selected {
    color: #fff;
    background: #414d5f;
  }
}

@keyframes HideList {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */

.dropdown-content-footer {
  display: none;
  position: absolute;
  background-color: var(--white);
  min-width: 160px;
  width: max-content;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 30;
  bottom: -12px;
  left: 52px;
  /* Here I have set the width (minus padding) of the dropdown-content */
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--white);
  min-width: 160px;
  width: max-content;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 30;
  top: -16px;
  left: 53px;
  border-radius: 8px;
  /* Here I have set the width (minus padding) of the dropdown-content */
}

/* Links inside the dropdown */

.dropdown-content a,
.dropdown-content-footer a {
  color: var(--black);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  /* background-color: var(--white); */
}

/* Change color of dropdown links on hover */

/* Show the dropdown menu on hover */

.dropdown:hover .dropdown-content,
.dropdown:hover .dropdown-content-footer {
  display: block;
  background-color: var(--white);
}

/* Change the background color of the dropdown button when the dropdown content is shown */

.dropdown:hover .dropbtn {
  background-color: #d60707;
}

.bg-active-icon-submenu {
  background-color: var(--active);
}

.bg-active-icon-submenu-tooltip {
  background-color: var(--active);
  border-right: 5px solid var(--primary-yellow);
}

.bg-active-icon-submenu-tooltip:hover {
  background-color: var(--hover);
  border-right: 5px solid var(--primary-yellow);
}

.bg-inactive-icon-submenu-tooltip {
  background-color: var(--white);
}

.bg-inactive-icon-submenu-tooltip:hover {
  background-color: var(--hover);
}

.active-selected-box {
  background-color: var(--primary-yellow);
  border-radius: 4px;
  width: 4px;
  height: 18px;
  align-self: center;
  margin-left: 10px;
}
</style>
