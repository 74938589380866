import Vue from "vue";

import AlertIcon from "@/assets/icons/AlertIcon.vue";
import AnalyticsIcon from "@/assets/icons/AnalyticsIcon.vue";
import ArrowDownIcon from "@/assets/icons/ArrowDownIcon.vue";
import AvatarIcon from "@/assets/icons/AvatarIcon.vue";
import BallotIcon from "@/assets/icons/BallotIcon.vue";
import CalendarIcon from "@/assets/icons/CalendarIcon.vue";
import CameraIcon from "@/assets/icons/CameraIcon.vue";
import CheckIcon from "@/assets/icons/CheckIcon.vue";
import CloseIcon from "@/assets/icons/CloseIcon.vue";
import CloudIcon from "@/assets/icons/CloudIcon.vue";
import DataNotFoundIcon from "@/assets/icons/DataNotFoundIcon.vue";
import DeleteIcon from "@/assets/icons/DeleteIcon.vue";
import DeleteModalIcon from "@/assets/icons/DeleteModalIcon.vue";
import DocumentIcon from "@/assets/icons/DocumentIcon.vue";
import DuplicateIcon from "@/assets/icons/DuplicateIcon.vue";
import EditIcon from "@/assets/icons/EditIcon.vue";
import ErrorIcon from "@/assets/icons/ErrorIcon.vue";
import ExclamationIcon from "@/assets/icons/ExclamationIcon.vue";
import ExitIcon from "@/assets/icons/ExitIcon.vue";
import HamburgerIcon from "@/assets/icons/HamburgerIcon.vue";
import HelpIcon from "@/assets/icons/HelpIcon.vue";
import ImageIcon from "@/assets/icons/ImageIcon.vue";
import InProcessIcon from "@/assets/icons/InProcessIcon.vue";
import LockOpenIcon from "@/assets/icons/LockOpenIcon.vue";
import MarkChatIcon from "@/assets/icons/MarkChatIcon.vue";
import MemoNoteIcon from "@/assets/icons/MemoNoteIcon.vue";
import NextIcon from "@/assets/icons/NextIcon.vue";
import NoteIcon from "@/assets/icons/NoteIcon.vue";
import OrganizationIcon from "@/assets/icons/OrganizationIcon.vue";
import PaperClipIcon from "@/assets/icons/PaperClipIcon.vue";
import PlusIcon from "@/assets/icons/PlusIcon.vue";
import ProfileIcon from "@/assets/icons/ProfileIcon.vue";
import SettingIcon from "@/assets/icons/SettingIcon.vue";
import StatusCircleIcon from "@/assets/icons/StatusCircleIcon.vue";
import SuccessIcon from "@/assets/icons/SuccessIcon.vue";
import SuccessOrangeIcon from "@/assets/icons/SuccessOrangeIcon.vue";
import UploadFilesIcon from "@/assets/icons/UploadFilesIcon.vue";
import WarningIcon from "@/assets/icons/WarningIcon.vue";
import XIcon from "@/assets/icons/XIcon.vue";
import ReportIcon from "@/assets/icons/ReportIcon.vue";
import LogMessageIcon from "@/assets/icons/LogMessageIcon.vue";
import AlertLogMessageIcon from "@/assets/icons/AlertLogMessageIcon.vue";
import DatePickerIcon from "@/assets/icons/DatePickerIcon.vue";
import TutorialIcon from "@/assets/icons/TutorialIcon.vue";
import CheckStepIcon from "@/assets/icons/CheckStepIcon.vue";
import CheckOrangeIcon from "@/assets/icons/CheckOrangeIcon.vue";
import EngIcon from "@/assets/icons/EngIcon.vue";
import ThaiIcon from "@/assets/icons/ThaiIcon.vue";
import EyeIcon from "@/assets/icons/EyeIcon.vue";
import CommentIcon from "@/assets/icons/CommentIcon.vue";
import HistoryIcon from "@/assets/icons/HistoryIcon.vue";
import OpenNewTabIcon from "@/assets/icons/OpenNewTabIcon.vue";
import NotificationThread from "@/assets/icons/NotificationThreadIcon.vue";
import NotificationIcon from "@/assets/icons/NotificationIcon.vue";
import AttachmentsIcon from "@/assets/icons/AttachmentsIcon.vue";

Vue.component("alert-icon", AlertIcon);
Vue.component("analytics-icon", AnalyticsIcon);
Vue.component("arrow-down-icon", ArrowDownIcon);
Vue.component("avatar-icon", AvatarIcon);
Vue.component("ballot-icon", BallotIcon);
Vue.component("calendar-icon", CalendarIcon);
Vue.component("check-icon", CheckIcon);
Vue.component("close-icon", CloseIcon);
Vue.component("camera-icon", CameraIcon);
Vue.component("cloud-icon", CloudIcon);
Vue.component("data-not-found-icon", DataNotFoundIcon);
Vue.component("delete-icon", DeleteIcon);
Vue.component("delete-modal-icon", DeleteModalIcon);
Vue.component("document-icon", DocumentIcon);
Vue.component("duplicate-icon", DuplicateIcon);
Vue.component("edit-icon", EditIcon);
Vue.component("error-icon", ErrorIcon);
Vue.component("exclamation-icon", ExclamationIcon);
Vue.component("exit-icon", ExitIcon);
Vue.component("hamburger-icon", HamburgerIcon);
Vue.component("help-icon", HelpIcon);
Vue.component("image-icon", ImageIcon);
Vue.component("in-process-icon", InProcessIcon);
Vue.component("lock-open-icon", LockOpenIcon);
Vue.component("mark-chat-icon", MarkChatIcon);
Vue.component("memo-note-icon", MemoNoteIcon);
Vue.component("next-icon", NextIcon);
Vue.component("note-icon", NoteIcon);
Vue.component("organization-icon", OrganizationIcon);
Vue.component("paper-clip-icon", PaperClipIcon);
Vue.component("plus-icon", PlusIcon);
Vue.component("profile-icon", ProfileIcon);
Vue.component("setting-icon", SettingIcon);
Vue.component("status-circle-icon", StatusCircleIcon);
Vue.component("success-icon", SuccessIcon);
Vue.component("upload-files-icon", UploadFilesIcon);
Vue.component("warning-icon", WarningIcon);
Vue.component("x-icon", XIcon);
Vue.component("report-icon", ReportIcon);
Vue.component("log-message-icon", LogMessageIcon);
Vue.component("alert-log-message-icon", AlertLogMessageIcon);
Vue.component("date-picker-icon", DatePickerIcon);
Vue.component("tutorial-icon", TutorialIcon);
Vue.component("check-step-icon", CheckStepIcon);
Vue.component("eng-icon", EngIcon);
Vue.component("thai-icon", ThaiIcon);
Vue.component("success-orange-icon", SuccessOrangeIcon);
Vue.component("check-orange-icon", CheckOrangeIcon);
Vue.component("eye-icon", EyeIcon);
Vue.component("comment-icon", CommentIcon);
Vue.component("history-icon", HistoryIcon);
Vue.component("open-new-tab-icon", OpenNewTabIcon);
Vue.component("notification-thread-icon", NotificationThread);
Vue.component("notification-icon", NotificationIcon);
Vue.component("attachments-icon", AttachmentsIcon);
