<template>
  <div class="d-flex justify-content-center align-items-center gap-2">
    <component :is="typeIcon" />
    <div style="padding-right: 16px">
      <typo type="body_web">
        {{ message }}
      </typo>
    </div>
  </div>
</template>

<script>
export default {
  name: "AlertMessage",
  props: {
    type: {
      type: String,
    },
    message: {
      type: String,
    },
  },
  computed: {
    typeIcon() {
      switch (this.type) {
        case "success":
          return "success-icon";
        case "error":
          return "error-icon";
        case "warning":
          return "warning-icon";
        case "in-process":
          return "in-process-icon";
        case "note":
          return "note-icon";
        default:
          return null;
      }
    },
  },
};
</script>
