<script>
import {
  LinkButton,
  PrimaryButton,
  SecondaryButton,
  DangerButton,
  ColorButton,
  TutorialButton,
  RejectButton,
  ListButton
} from "./button.styled.js";

export default {
  props: {
    type: String,
    disabled: Boolean,
    block: Boolean,
    underline: Boolean,
  },
  components: {
    PrimaryButton,
    SecondaryButton,
    ColorButton,
    LinkButton,
    DangerButton,
    TutorialButton,
    RejectButton,
    ListButton
  },
  computed: {
    componentType() {
      switch (this.type) {
        case "primary":
          return "PrimaryButton";
        case "secondary":
          return "SecondaryButton";
        case "color":
          return "ColorButton";
        case "danger":
          return "DangerButton";
        case "link":
          return "LinkButton";
        case "tutorial":
          return "TutorialButton";
        case "reject":
          return "RejectButton";
        case "list":
          return "listButton";
        default:
          return "PrimaryButton";
      }
    },
  },
};
</script>

<template>
  <component
    :is="componentType"
    @click="$emit('on-click')"
    :style="{
      width: block ? '100%' : '',
    }"
    :disabled="disabled"
  >
    <slot />
  </component>
</template>
