<template>
  <styled-modal
    :title="title"
    :content="content"
    :id="id"
    icon
    :size="size || 'sm'"
    :hideBackdrop="id == 'modal-warning-double-login' ? true : false"
    hideHeader
    :hide-close="hideClose"
    @on-hidden="$emit('closeFunction')"
  >
    <template v-slot:icon>
      <slot name="icon">
        <alert-icon />
      </slot>
    </template>
    <slot name="body"></slot>
    <template v-slot:footer>
      <div class="d-flex gap-2 justify-content-end w-100">
        <div
          class="w-100"
          :class="{
            'd-flex gap-2 justify-content-between':
              isCancelBtn && isCustomBtnSubmit != true,
          }"
          v-if="typeof submitFunction === 'function'"
        >
          <styled-button
            v-if="isCancelBtn"
            :type="'secondary'"
            class="w-100"
            :class="{
              'mb-2': isCustomBtnSubmit == true,
            }"
            @on-click="closeModal"
            :block="!size"
          >
            {{ btnCloseName ? btnCloseName : $t("BTN_CANCEL") }}
          </styled-button>
          <styled-button
            type="primary"
            class="w-100"
            @on-click="submitFunction(data)"
            :block="!size"
          >
            {{ btnSubmitName ? btnSubmitName : $t("BTN_OK") }}
          </styled-button>
        </div>
        <div class="w-100" v-else>
          <styled-button
            :type="closeType ? closeType : 'secondary'"
            @on-click="closeModal"
            :block="isFullSizeBtnClose || !size"
          >
            {{ btnCloseName ? btnCloseName : $t("BTN_CLOSE") }}
          </styled-button>
        </div>
      </div>
    </template>
  </styled-modal>
</template>

<script>
import StyledModal from "@/components/Modal/StyledModal.vue";

export default {
  props: [
    "id",
    "size",
    "title",
    "content",
    "btnCloseName",
    "closeType",
    "isCancelBtn",
    "data",
    "submitFunction",
    "btnSubmitName",
    "isCustomBtnSubmit",
    "isCloseFunction",
    "isFullSizeBtnClose",
    "hideClose"
  ],
  components: {
    StyledModal,
  },
  methods: {
    closeModal() {
      if (!this.isCloseFunction) {
        this.$bvModal.hide(`${this.id}`);
      } else {
        this.$emit("submitCloseFunction");
      }
    },
  },
};
</script>
