export default {
    namespaced: true,
    state: {
        secound_ef: null,
        third_ef: null,
        isCloseEf : false,
        listEfShow : [],
        indexEditList : 0
    },
    mutations: {
        setSecoundEf : (state, payload) => {
          state.secound_ef = payload;
        },
        setThirdEf : (state, payload) => {
            state.third_ef = payload;
        },
        setIsCloseEf : (state, payload) => {
            state.isCloseEf = payload;
        },
        clearEfAll : (state) => {
            state.secound_ef = null;
            state.third_ef = null;
        },
        setListShowEf :(state,payload) => {
            // state.listEfShow = payload
            if(payload) {
                state.listEfShow.push(...payload)
            } else {
                state.listEfShow = []
            }
            
        },
        deleteListsEf :(state,index) => {
            state.listEfShow.splice(index,1)
        },
        setIndexEditList :(state,index) => {
            state.indexEditList = index
        },
        editListEf :(state,payload) => {
            state.listEfShow.splice(payload.index,1,payload)
            // state.indexEditList = payload
        },
    },
    actions: {
        setSecoundEf : (state, payload) => {
          state.commit("setSecoundEf", payload);
        },
        setThirdEf : (state, payload) => {
            state.commit("setThirdEf", payload);
        },
        setIsCloseEf : (state, payload) => {
            state.commit("setIsCloseEf", payload);
        },
        setListShowEf : (state, payload) => {
            state.commit("setListShowEf", payload);
        },
        deleteListsEf : (state, index) => {
            state.commit("deleteListsEf", index);
        },
        setIndexEditList : (state, index) => {
            state.commit("setIndexEditList", index);
        },
        editListEf : (state, payload) => {
            state.commit("editListEf", payload);
        },
    },
    getters: {
        getEf2: (state) => state.secound_ef,
        getEf3: (state) => state.third_ef,
        isCloseEf: (state) => state.isCloseEf,
        listShowEf : (state) => state.listEfShow
    },
}