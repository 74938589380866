export default {
    namespaced: true,
    state: {
        auditorTab: [
            { id: 1, name: "LB_ACTIVITY_LIST", url: "/auditor-activity" },
            { id: 2, name: "LB_EMISSION_FACTOR", url: "/auditor-emission-factor" },
            { id: 3, name: "TXT_ORGANIZATIONAL_DIVSION", url: "/auditor-orgnizaion-fractionation" }
        ],
    },
    mutations: {

    },
    actions: {

    },
    getters: {
        auditorTab: (state) => state.auditorTab,
    },
}