<script>
export default {
  props: {
    visible: Boolean,
    title: String,
    id: String,
    cancelTitle: String,
    okTitle: String,
    content: String,
    submitFunction: Function,
    size: String,
    hideClose: Boolean,
    hideBackdrop: Boolean,
    hideFooter: Boolean,
    hideHeader: Boolean,
    noFade: Boolean,
    icon: Boolean,
    show: Boolean,
    hideCloseBtn : Boolean,
    loading: Boolean
  },
};
</script>


<template>
  <b-modal
    :visible="visible"
    centered
    :id="id"
    :size="size"
    :no-fade="noFade"
    no-close-on-backdrop
    :hide-backdrop="hideBackdrop"
    scrollable
    :hide-footer="hideFooter"
    :hide-header="hideHeader"
    hide-header-close
    @shown="$emit('on-shown')"
    @hidden="$emit('on-hidden')"
    class="position-relative"
  >
    <template #modal-header="{}">
      <slot name="header">
        <div class="d-flex justify-content-between" style="gap: 16px">
          <div class="d-flex flex-column">
            <typo type="h4_web" class="mb-2">
              {{ title }}
            </typo>
            <typo type="body_web">
              <div v-html="content"></div>
            </typo>
          </div>
          <div
            v-if="!hideCloseBtn"
            style="cursor: pointer"
            @click="$bvModal.hide(`${id}`)"
            :hidden="hideClose"
          >
            <x-icon />
          </div>
        </div>
      </slot>
    </template>
    <div v-if="icon" class="d-flex justify-content-between" style="gap: 16px">
      <div class="d-flex align-items-center">
        <div class="align-self-baseline">
          <slot name="icon" />
        </div>
      </div>
      <div
        class="d-flex flex-column"
        :class="{ 'justify-content-start ms-2 w-100': icon }"
      >
        <typo type="h4_web" class="mb-2">
          {{ title }}
        </typo>
        <slot name="content">
          <typo type="body_web">
            <div v-html="content"></div>
          </typo>
        </slot>
      </div>
      <div
        style="cursor: pointer"
        @click="$bvModal.hide(`${id}`)"
        :hidden="hideClose"
      >
        <x-icon />
      </div>
    </div>
    <b-overlay no-wrap :show="loading" />
    <slot />

    <template #modal-footer="{}">
      <b-overlay :show="show" no-wrap> </b-overlay>
      <div style="padding-top: 8px" class="w-100">
        <slot name="footer" />
      </div>
    </template>
  </b-modal>
</template>