import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { apiURL } from "@/configs/serverConfig";
import Cookies from "js-cookie";

import ef from "./ef";
import menu from "./menu";
import dashboard from "./dashboard";
import tabList from "./tabList";
import register from "./register";
import selectLang from "./selectLang";
import verifyAgency from "./verifyAgency";
import verify from "./verify";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    refreshToken: null,
    token: "",
    companyId: "",
    companyName: "",
    role: "",
    company_image_url: null,
    admin_type: null,
    setup_type: null,
    main_company: null,
    main_company_role: null,
    role_action: null,
    // secoundEf : 1
    permissions: null,
    auditor_type: null,
    status_active: false,
    isAuditorHaveCompany: false,
    loginType: 0,
    userType: null,
    userId: null,
    memberId: null,
    oauth_token: null,
    oauth_refresh_token: null,
    count_double_login: 0,
    member_data: null,
    contact_admin: [],
    count_of_call_login_setlink : 0,
    user_data : null
  },
  mutations: {
    setMainCompany: (state, payload) => {
      state.main_company = payload;
    },
    setMainCompanyRole: (state, payload) => {
      state.main_company_role = payload;
    },
    setCompanyId: (state, payload) => {
      state.companyId = payload;
    },
    setCompanyName: (state, payload) => {
      state.companyName = payload;
    },
    setRoleId: (state, payload) => {
      state.role = payload;
    },
    setCompanyImage: (state, payload) => {
      state.company_image_url = payload;
    },
    setAdminType: (state, payload) => {
      state.admin_type = payload;
    },
    setUpType: (state, payload) => {
      state.setup_type = payload;
    },
    setRoleAction: (state, payload) => {
      state.role_action = payload;
    },
    setToken: (state, payload) => {
      state.token = payload;
    },
    setRefreshToken: (state, payload) => {
      state.refreshToken = payload;
    },
    // setSecoundEf : (state, payload) => {
    //   state.secoundEf = payload;
    // },
    setPermission: (state, payload) => {
      state.permissions = payload;
    },
    setAuditorType: (state, payload) => {
      state.auditor_type = payload;
    },
    setStatusActive: (state, payload) => {
      state.status_active = payload;
    },
    setIsAuditHaveCompany: (state, payload) => {
      state.isAuditorHaveCompany = payload;
    },
    setLoginType: (state, payload) => {
      state.loginType = payload;
    },
    setUserType: (state, payload) => {
      state.userType = payload;
    },
    setUserIdLogin: (state, payload) => {
      state.userId = payload;
    },
    setMemberIdLogin: (state, payload) => {
      state.memberId = payload;
    },
    setOAuthToken: (state, payload) => {
      state.oauth_token = payload;
    },
    setOAuthRefreshToken: (state, payload) => {
      state.oauth_refresh_token = payload;
    },
    setCountDoubleLogin: (state, payload) => {
      state.count_double_login = payload;
    },
    setMemberData: (state, payload) => {
      state.member_data = payload;
    },
    setContactAdmin: (state, payload) => {
      state.contact_admin = payload;
    },
    setCountOfCallFunctionLoginSetlinkPlus: (state) => {
      state.count_of_call_login_setlink++;
    },
    setZeroCountCallLoginSetLink: (state) => {
      state.count_of_call_login_setlink = 0;
    },
    setUserData: (state,payload) => {
      state.user_data = payload;
    }
  },
  actions: {
    setMainCompany: (state, payload) => {
      state.commit("setMainCompany", payload);
    },
    setMainCompanyRole: (state, payload) => {
      state.commit("setMainCompanyRole", payload);
    },
    setToken: (state, payload) => {
      state.commit("setToken", payload);
      // localStorage.setItem('session_id', payload)
      // let session_token = localStorage.getItem('session_id');
      // state.commit("setToken", session_token);
    },
    setCompanyId: (state, payload) => {
      state.commit("setCompanyId", payload);
    },
    setCompanyName: (state, payload) => {
      state.commit("setCompanyName", payload);
    },
    setRoleId: (state, payload) => {
      state.commit("setRoleId", payload);
    },
    setCompanyImage: (state, payload) => {
      state.commit("setCompanyImage", payload);
    },
    setAdminType: (state, payload) => {
      state.commit("setAdminType", payload);
    },
    setUpType: (state, payload) => {
      state.commit("setUpType", payload);
    },
    setRoleAction: (state, payload) => {
      state.commit("setRoleAction", payload);
    },
    // setSecoundEf : (state, payload) => {
    //   state.commit("setSecoundEf", payload);
    // },
    setPermission: (state, payload) => {
      state.commit("setPermission", payload);
    },

    setPermissionLogin: (state, payload) => {
      state.commit("setPermission", payload);
    },

    setAuditorType: (state, payload) => {
      state.commit("setAuditorType", payload);
    },

    setStatusActive: (state, payload) => {
      state.commit("setStatusActive", payload);
    },

    setIsAuditHaveCompany: (state, payload) => {
      state.commit("setIsAuditHaveCompany", payload);
    },

    setLoginType: (state, payload) => {
      state.commit("setLoginType", payload);
    },
    // เช็ค user type สำหรับการกรอกข้อมูลหน้า audit (เป็น user หรือ audit)
    setUserType: (state, payload) => {
      state.commit("setUserType", payload);
    },

    setUserIdLogin: (state, payload) => {
      state.commit("setUserIdLogin", payload);
    },

    setMemberIdLogin: (state, payload) => {
      state.commit("setMemberIdLogin", payload);
    },

    setOAuthToken: (state, payload) => {
      state.commit("setOAuthToken", payload);
    },

    setOAuthRefreshToken: (state, payload) => {
      state.commit("setOAuthRefreshToken", payload);
    },

    setCountDoubleLogin: (state, payload) => {
      state.commit("setCountDoubleLogin", payload);
    },

    setMemberData: (state, payload) => {
      state.commit("setMemberData", payload);
    },
    async getPermission({ state, dispatch }) {
      const permissions = state.main_company.page_permission;
      await dispatch("setPermission", permissions);
    },
    setRefreshToken: (state, payload) => {
      state.commit("setRefreshToken", payload);
    },
    setContactAdmin: (state, payload) => {
      state.commit("setContactAdmin", payload);
    },
    setCountOfCallFunctionLoginSetlinkPlus: (state) => {
      state.commit("setCountOfCallFunctionLoginSetlinkPlus");
    },
    setZeroCountCallLoginSetLink: (state) => {
      state.commit("setZeroCountCallLoginSetLink");
    },
    setUserData: (state, payload) => {
      state.commit("setUserData", payload);
    },
    async refreshToken({ commit, state }) {
      try {
        const response = await axios.post(`${apiURL}/guest/refresh_token`, {
          refresh_token: state.refreshToken,
        });
        commit("setToken", response.data.access_token);
        commit("setRefreshToken", response.data.refresh_token);
        return response.data.access_token;
      } catch (error) {
        console.error("Refresh token failed:", error);
        throw error;
      }
    },
    async removeSession({ commit }) {
      try {
        console.log("Session removed successfully, navigating to login page.");
        localStorage.clear();
        Cookies.remove("session");
        commit("setToken", null);
        commit("setRefreshToken", null);
        commit("setOAuthToken", null);
        commit("setOAuthRefreshToken", null);
        commit("setMemberData", null);
        // Add any other state clearing logic here
      } catch (error) {
        console.error("Failed to remove session:", error);
        throw error;
      }
    },
  },
  modules: {
    ef,
    menu,
    register,
    dashboard,
    tabList,
    selectLang,
    verifyAgency,
    verify,
  },
  getters: {
    getToken: (state) => state.token,
    getCompanyId: (state) => state.companyId,
    getCompanyName: (state) => state.companyName,
    getRoleId: (state) => state.role,
    getPermissions: (state) => state.permissions,
    getSetupType: (state) => state.setup_type,
    getAuditorType: (state) => state.auditor_type,
    getLoginType: (state) => state.loginType,
    getUserType: (state) => state.userType,
    getAdminType: (state) => state.admin_type,
    getMainCompanyRole: (state) => state.main_company_role,
  },
  plugins: [createPersistedState()],
});

// export default store;
