<template>
  <div>
    <Navbar @toggleMenu="toggleMenu" />
    <div class="app-side-bar" style="background-color: var(--tertiary-grey_10)">
      <SideBar :toggleMenu="is_expanded" ref="sidebarMenu" />
      <div class="main-content pt-0 overflow-y-auto" :class="checkOverflow()">
        <!-- Header Section -->
        <div
          :class="{
            'position-header1': !fixed_header,
            'position-header2 bg-white': fixed_header,
          }"
          ref="headerSlot"
          v-if="fixed_header || !fixed_header"
        >
          <div style="overflow-x: hidden">
            <div
              class="bg-get-start px-4 my-auto d-flex align-middle"
              style="height: 30px"
            >
              <!-- Breadcrumb -->
              <b-breadcrumb class="my-auto">
                <b-breadcrumb-item :href="$route.matched[0]?.path">
                  <span
                    v-if="fixed_header"
                    style="color: var(--tertiary-sky_blue_100)"
                  >
                    {{ $t("TXT_HOME") }}
                  </span>
                  <span v-else>
                    {{ $t("TXT_HOME") }}
                  </span>
                </b-breadcrumb-item>
                <span class="mx-2">/</span>
                <template v-for="(matched, idx) in $route.matched">
                  <b-breadcrumb-item
                    :key="`breadcrumb-${idx}`"
                    :href="matched.path"
                    :active="$route.name === matched.name"
                  >
                    {{ $t(`${matched.meta.key}`) }}
                  </b-breadcrumb-item>
                  <span
                    :key="`separator-${idx}`"
                    class="mx-2"
                    v-if="idx !== $route.matched.length - 1"
                  >
                    /
                  </span>
                </template>
              </b-breadcrumb>
            </div>
          </div>
          <div v-if="fixed_header" style="overflow-x: hidden">
            <slot name="header"></slot>
          </div>
        </div>

        <!-- Body Section -->
        <div class="z-0">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar/NavbarHeader.vue";
import SideBar from "@/components/Sidebar/SidebarMenu.vue";

export default {
  components: {
    Navbar,
    SideBar,
  },
  data() {
    const { meta } = this.$route;
    return {
      is_expanded: this.$store.state.menu.is_expanded,
      colToggle: true,
      active: false,
      fixed_header: meta?.fixed_top_position_header || null,
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.getHeaderHeight();
      window.addEventListener("resize", this.getHeaderHeight);
    });
  },

  methods: {
    toggleMenu(is_expanded) {
      this.is_expanded = is_expanded;
    },
    getHeaderHeight() {
      const headerSlot = this.$refs.headerSlot;
      const headerContentHeight = headerSlot?.offsetHeight || 0;

      const totalHeight = headerContentHeight + 48 + 39 + 27; // Sum the fixed offsets
      document.documentElement.style.setProperty(
        "--headerHeight",
        `${totalHeight}px`
      );
    },
    toggleCollapse() {
      this.colToggle = !this.colToggle;
    },
    checkOverflow() {
      return this.$route.meta.overflow_x_layout
        ? "overflow-x-auto"
        : "overflow-x-hidden";
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-get-start {
  background: #fff;
  height: 4em;
  text-align: left;
  align-items: center;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.position-header1 {
  position: sticky;
  left: 0;
  z-index: 20;
}

.position-header2 {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 20;
}

/* .fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
} */

.fade-enter-active,
.fade-leave-active {
  transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: scale(0.3) translateY(-50%);
}

@keyframes fade-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
