import Vue from 'vue'

import AppLayoutMain from "@/layouts/AppLayoutMain.vue";
import StyledToast from "@/components/Toast/StyledToast.vue";
import Typo from "@/components/Text/StyledText";
import StyledButton from "@/components/Button/StyledButton.vue";
import EmptyTable from "@/components/EmptyTable.vue";

Vue.component('app-layout-main', AppLayoutMain);
Vue.component('styled-toast', StyledToast);
Vue.component('typo', Typo);
Vue.component('styled-button', StyledButton);
Vue.component('empty-table', EmptyTable);
