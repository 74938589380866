<template>
  <div>
    <slot />
  </div>
</template>

<script>
const defaultLayout = "AppLayoutDefault";
const defaultMobile = "AppLayoutMobile";

export default {
  name: "AppLayout",
  computed: {
    layout() {
      const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );

      const layoutName =
        this.$route.meta.layout || (isMobile ? defaultMobile : defaultLayout);

      return () => import(`@/layouts/${layoutName}.vue`);
    },
  },
};
</script>
