import { render, staticRenderFns } from "./AppLayoutMain.vue?vue&type=template&id=79c11752&scoped=true"
import script from "./AppLayoutMain.vue?vue&type=script&lang=js"
export * from "./AppLayoutMain.vue?vue&type=script&lang=js"
import style0 from "./AppLayoutMain.vue?vue&type=style&index=0&id=79c11752&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79c11752",
  null
  
)

export default component.exports