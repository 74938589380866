import { colors } from "../../styles/theme.styled";
import { BodyStyle } from "../../styles/typo.styled";
import styled from "vue-styled-components";

export const LinkButton = styled.button`
  ${BodyStyle}
  height: 16px;
  display: flex;
  min-width: max-content;
  color: ${colors.tertiary.sky_blue_100};
  &:hover:not(:disabled) {
    color: ${colors.tertiary.sky_blue_60};
    text-decoration: underline;
  }
  &:active:not(:disabled) {
    color: ${colors.tertiary.sky_blue_30};
  }
  &:disabled {
    color: ${colors.transparent.black_35};
  }
`;
export const ListButton = styled.button`
  min-width: 70px;
  min-height: 32px;
  padding: 6px 12px;
  border-radius: 4px;
  text-align: start;
  &:hover:not(:disabled) {
    background-color: ${colors.tertiary.orange_10};
    background-image: none;
  }
`;

export const TutorialButton = styled.button`
  display: flex;
  width: 56px;
  height: 56px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 20px;
  bottom: 32px;
  border-radius: 37.8px;
  background-image: var(--gradient-yellow);
  z-index: 10;
`;

export const BasicButton = styled.button`
  ${BodyStyle}
  min-width: 70px;
  min-height: 32px;
  padding: 6px 12px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    background-color: ${colors.transparent.black_10};
    color: ${colors.transparent.black_35};
    background-image: none;
    border: 1px solid ${colors.transparent.white_96};
  }
`;

export const PrimaryButton = BasicButton.extend`
  background-image: ${colors.gradient.orange_100};
  border-color: ${colors.primary.orange};
  color: ${colors.primary.black};
  &:hover:not(:disabled) {
    background-color: ${colors.tertiary.yellow};
    background-image: none;
  }
  &:active:not(:disabled) {
    background-color: ${colors.tertiary.yellow_100};
    background-image: none;
  }
`;

export const DangerButton = BasicButton.extend`
  background-image: ${colors.gradient.red};
  color: ${colors.primary.white};
`;

export const RejectButton = BasicButton.extend`
  background: ${colors.tertiary.purplish_red_100};
  color: ${colors.primary.white};
`;

export const SecondaryButton = BasicButton.extend`
  background-image: ${colors.gradient.white_100};
  border: 1px solid ${colors.transparent.black_10};
  color: ${colors.primary.black};
  &:hover:not(:disabled) {
    border-color: ${colors.primary.yellow};
  }
  &:active:not(:disabled) {
    border-color: ${colors.tertiary.yellow_100};
  }
`;

export const ColorButton = BasicButton.extend`
  background-color: ${colors.tertiary.yellow_green_60};
  border: 1px solid ${colors.tertiary.yellow_green_60};
  color: ${colors.primary.black};
  &:hover:not(:disabled) {
    border-color: ${colors.accessible.green};
  }
  &:active:not(:disabled) {
    border-color: ${colors.tertiary.yellow_green_30};
  }
`;
