import { css } from 'vue-styled-components'

export const H1Style = css`
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 800;
  font-size: 2.857rem;
  line-height: 145%;
`

export const H2Style = css`
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 800;
  font-size: 2.143rem;
  line-height: 145%;
`

export const H3Style = css`
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 800;
  font-size: 1.571rem;
  line-height: 145%;
`

export const H4Style = css`
  font-family: "Sarabun";
  font-size: 1.143rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`

export const BodyStyle = css`
  font-family: "Sarabun";
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 145%;
`

export const BodyLinkStyle = css`
  font-family: "Sarabun";
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 145%;
  text-decoration-line: underline;
`

export const BodyBoldStyle = css`
  font-family: "Sarabun";
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 145%;
`

export const BodySmallStyle = css`
  font-family: "Sarabun";
  font-style: normal;
  font-weight: normal;
  font-size: 0.714rem;
  line-height: 145%;
`

export const BodyBoldSmallStyle = css`
  font-family: "Sarabun";
  font-style: normal;
  font-weight: bold;
  font-size: 0.714rem;
  line-height: 145%;
`

export const H1PDFStyle = css`
  font-family: "Sarabun";
  font-style: normal;
  font-weight: bold;
  font-size: 0.786rem;
  line-height: 1rem;
`

export const H2PDFStyle = css`
  font-family: "Sarabun";
  font-style: normal;
  font-weight: bold;
  font-size: 0.786rem;
  line-height: 170%;
`

export const BodyPDFStyle = css`
  font-family: "Sarabun";
  font-style: normal;
  font-weight: normal;
  font-size: 0.786rem;
  line-height: 170%;
`

export const BodyBoldPDFStyle = css`
  font-family: "Sarabun";
  font-style: normal;
  font-weight: bold;
  font-size: 0.786rem;
  line-height: 170%;
`