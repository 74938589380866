import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/index.js";
import vuetify from "./plugins/vuetify";
import "./components/components.js";
import "./assets/icons/icons.js";
import i18n from "@/js/i18n.js";
import { httpClient } from "@/services/axiosInstance.js";
import { apiURL } from "@/configs/serverConfig";
import Cookies from "js-cookie"; 
// import VueCookies from 'vue-cookies'

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import VueBootstrapAutocomplete from "@vue-bootstrap-components/vue-bootstrap-autocomplete";
import "bootstrap/scss/bootstrap.scss";
Vue.component("VueBootstrapAutocomplete", VueBootstrapAutocomplete);

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
Vue.component("v-select", vSelect);

// import { Select, TreeSelect, Pagination, Dropdown, Menu, Switch, Button, DatePicker} from "ant-design-vue";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";

import "./styles/index.scss";
import Toast from "vue-toastification";
import PrimeVue from "primevue/config";
// import VueI18n from "vue-i18n";
// import en from "./locales/en.json";
// import th from "./locales/th.json";
// import vi from "./locales/vi.json";
// Vue.use(VueI18n);

import * as VueGoogleMaps from "vue2-google-maps";

// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

// import './registerServiceWorker'
import ConfirmationService from "primevue/confirmationservice";

import InfiniteLoading from "vue-infinite-loading";
import VueSweetalert2 from "vue-sweetalert2";

import VueExcelXlsx from "vue-excel-xlsx";
import VueGtag from "vue-gtag";
import { VueReCaptcha } from "vue-recaptcha-v3";
import VueThailandAddress from "vue-thailand-address";
import imagePreview from "image-preview-vue";
import "image-preview-vue/lib/imagepreviewvue.css";

// import VueImageZoomer from 'vue-image-zoomer'
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/th";
import "vue2-datepicker/locale/en";

// import JsonExcel from "vue-json-excel";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);
import infiniteScroll from "vue-infinite-scroll";

Vue.use(infiniteScroll);

Vue.use(VueReCaptcha, {
  siteKey: "6LcPgtopAAAAAEGkfv3gn4O948TEDZTf8qWRI54K",
  loaderOptions: {
    useRecaptchaNet: true,
    autoHideBadge: true,
  },
});

// import "/src/services/service-worker-config.js";

import VueNumberFormat from "@coders-tm/vue-number-format";

Vue.use(VueNumberFormat);
// import { component as VueNumber } from '@coders-tm/vue-number-format'
// Vue.component('VueNumber', VueNumber);

// Vue.use(VueImageZoomer)

// Vue.component("downloadExcel", JsonExcel);

Vue.use(ConfirmationService);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: "places,maps,marker,geocoding,core", // This is required if you use the Autocomplete plugin
    region: `${store.state.selectLang.lang == 'en' ? "US" : "TH"}`,
    language: `${store.state.selectLang.lang || "th"}`,
    v: 3.57,
  },
});

Vue.use(InfiniteLoading, {
  props: {
    spinner: "default",
    /* other props need to configure */
  },
  system: {
    throttleLimit: 100,
    /* other settings need to configure */
  },
});

Vue.filter("formatNumberFull", function ([value, decimal]) {
  // ฟังก์ชันสำหรับการปัดทศนิยมแบบ e-notation
  function roundToDecimalPlaces(number, decimalPlaces) {
    return +(Math.round(number + `e+${decimalPlaces}`) + `e-${decimalPlaces}`);
  }

  const num = Number(value);

  if (decimal) {
    // ปัดทศนิยมตามจำนวนที่ระบุ
    const formattedNumber = roundToDecimalPlaces(num, decimal);
    return formattedNumber.toLocaleString(undefined, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal
    });
  } else {
    // ตรวจสอบจำนวนทศนิยมจากค่าจริง
    const decimalPlaces = value.toString().split(".")[1]?.length || 0;
    const formattedNumber = roundToDecimalPlaces(num, decimalPlaces);
    return formattedNumber.toLocaleString(undefined, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces
    });
  }
});

export function formatNumberFull(value, decimal) {
  if (decimal) {
    // return (parseFloat(value.toFixed(decimal).toLocaleString(undefined, { maximumFractionDigits: decimal })));
    return value.toFixed(decimal).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  } else {
    return parseFloat(value.toFixed(2)).toLocaleString();
  }
}

Vue.filter("formatNumber", function (number) {
  return new Intl.NumberFormat("en-US", {
    maximumSignificantDigits: 3,
    notation: "compact",
    compactDisplay: "short",
  }).format(number);
});

export function formatNumber(number) {
  return new Intl.NumberFormat("en-US", {
    maximumSignificantDigits: 3,
    notation: "compact",
    compactDisplay: "short",
  }).format(number);
}
export function formatNumberString(value) {
  let new_value = value;
  // console.log(new_value, isNaN(new_value));
  if (new_value) {
    if (isNaN(new_value)) {
      new_value = parseFloat(new_value.replace(/[^\d.]/g, "")).toLocaleString(
        "en-US"
      );
    } else {
      new_value = parseFloat(new_value).toLocaleString("en-US");
    }
  }
  return new_value;
}

// const messages = {
//   en: en.en,
//   th: th.th,
//   vi: vi.vi,
// };

// const i18n = new VueI18n({
//   locale: store.state.selectLang.lang ? store.state.selectLang.lang : "en",
//   // locale : "en",
//   messages,
// });

DatePicker.locale(store.state.selectLang.lang);
Vue.component("DatePicker", DatePicker);

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GTAG_ID },
});

const options = {
  position: "top-center",
  timeout: 3000,
  closeOnClick: false,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: false,
  rtl: false,
  toastClassName: "styled-toast",
  bodyClassName: "styled-toast-body",
  closeButtonClassName: "styled-close-button",
};

Vue.use(VueExcelXlsx);
Vue.use(imagePreview);
Vue.use(BootstrapVue);
Vue.use(Antd);
// Vue.use(VueCookies, { expires: '7d'});
// Vue.use(Select);
// Vue.use(TreeSelect);
// Vue.use(Pagination);
// Vue.use(Dropdown);
// Vue.use(Menu);
// Vue.use(Switch);
// Vue.use(Button);

Vue.use(IconsPlugin);
Vue.use(Toast, options);
Vue.use(PrimeVue);
Vue.use(VueThailandAddress);

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/service-worker.js')
//     .then(registration => {
//       console.log('Service Worker registered:', registration);
//     })
//     .catch(error => {
//       console.error('Error registering Service Worker:', error);
//     });
// }
// Vue.config.devtools = true;
Vue.config.productionTip = false;
// Vue.use(Vuetify);
Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    var vm = vnode.context;
    var callback = binding.value;

    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        return callback.call(vm, event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});
// console.log('App version:', process.env.VUE_APP_VERSION);
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js", {
        updateViaCache: "none",
      })
      .then((registration) => {
        registration.update();
        // console.log('SW registered: ', registration);
      })
      .catch((registrationError) => {
        this.$toast.error({
          component: "styled-toast",
          props: {
            type: "error",
            message: `SW registration failed: ${registrationError}`,
          },
        });
      });
  });
}
Vue.prototype.$globalLogout = async function() {
  const url = `${apiURL}/user/logout`;

      try {
        const logout = await httpClient.post(url);
        if (logout.status == 200) {
          localStorage.clear();
          Cookies.remove("session");
          this.$store.commit("setToken", "");
          this.$store.commit("setRefreshToken", "");
          this.$store.commit("setRoleAction", null);
          this.$nextTick(() => {
            // if (
            //   this.$store.state.user_data.type == 2 ||
            //   this.$store.state.user_data.type == 3
            // ) {
            //   //auditor
            //   this.$router.push("/login-auditor");
            // } else {
            //   this.$router.push("/");
            // }
            if (this.$store.state.oauth_token) {
              this.$store.commit("setOAuthToken", null);
              this.$store.commit("setOAuthRefreshToken", null);
              let logoutSession = window.open(
                `${process.env.VUE_APP_SETLINK_URL}/authen/logout`,
                "_blank"
              );
              const myTimeout = setTimeout(() => {
                logoutSession.close();
                clearTimeout(myTimeout);
              }, 300);
            }
          });
        }
      } catch (err) {
        if (err) {
          this.$toast.error({
            component: "styled-toast",
            props: {
              type: "error",
              message: err.response.data.message,
            },
          });
        }
      }
};

if (localStorage.getItem("app_version") !== process.env.VUE_APP_VERSION) {
  localStorage.setItem("app_version", process.env.VUE_APP_VERSION);
  window.location.reload(true);
}
// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     const baseUrl = `${window.location.origin}`;
//     const swUrl = `${baseUrl}/service-worker.js`; // Ensure path is correct

//     navigator.serviceWorker.register(swUrl).then((registration) => {
//       console.log('Service Worker registered with scope:', registration.scope);
//     }).catch((error) => {
//       console.log('Service Worker registration failed:', error,swUrl);
//     });
//   });
// }

new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
