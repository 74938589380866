<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <g :opacity="color ? 1 : 0.2">
      <path
        d="M6.93721 14.8996H3.7372C2.85354 14.8996 2.1372 14.1832 2.13721 13.2996L2.13727 3.6996C2.13727 2.81595 2.85362 2.09961 3.73727 2.09961H10.9374C11.8211 2.09961 12.5374 2.81595 12.5374 3.69961V8.09961M11.6 14.6251V12.3623M11.6 12.3623V10.0996M11.6 12.3623L9.33722 12.3623M11.6 12.3623L13.8627 12.3623M4.93746 5.29961H9.73746M4.93746 7.69961H9.73746M4.93746 10.0996H7.33746"
        :stroke="color ? color : 'black'"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: ["color"],
};
</script>
