<template>
  <styled-modal
    :title="title"
    :content="content"
    :id="id"
    icon
    :size="size || 'sm'"
    hideHeader
    hideClose
    @on-hidden="$emit('closeFunction')"
  >
    <template v-slot:icon>
      <slot name="icon">
        <alert-icon />
      </slot>
    </template>
  </styled-modal>
</template>

<script>
import StyledModal from "@/components/Modal/StyledModal.vue";

export default {
  props: [
    "id",
    "size",
    "title",
    "content",
    "btnCloseName",
    "closeType",
    "isCancelBtn",
    "data",
    "submitFunction",
    "btnSubmitName",
    "isCustomBtnSubmit",
    "isCloseFunction",
  ],
  components: {
    StyledModal,
  },
  methods: {
    closeModal() {
      if (this.isCloseFunction) {
        this.$emit("closeFunction");
      } else {
        this.$bvModal.hide(this.id);
      }
    },
  },
};
</script>
