export default {
    namespaced: true,
    state: {
        dashboardTab: [
            { id: 1, name: "ECO Balance", url: "/dashboard-eco" },
            { id: 2, name: "BTN_DASHBOARD_SUMMARY", url: "/dashboard-newghg" },
            { id: 3, name: "BTN_DASHBOARD_INSIGHTS", url: "/dashboard-tgo" },
        ],
        dashboardTabDenso : [
            { id : 1, name : "สรุปภาพรวม", url : "/dashboard-deepdetail"},
            { id : 2, name : "Co2 reduction", url : "/dashboard-co2reduction"},
            { id : 3, name : "Water reduction", url : "/dashboard-water-reduction"},
            { id: 4, name : "Waste reduction", url : "/dashboard-waste-reduction"},
            { id: 5, name : "ข้อมูลเชิงลึก", url : "/dashboard-tgo"},
        ],
        specialVariableTab : [
            { id: 1, text: "ทั้งหมด" },
            { id: 2, text: "BTN_DASHBOARD_SUMMARY" },
            { id: 3, text: "BTN_DASHBOARD_INSIGHTS" }
        ],
        specialVariableDensoTab : [
            { id: 1, text: "ทั้งหมด" },
            { id: 2, text: "สรุปภาพรวม" },
            { id: 3, text: "Co2 Reduction" },
            { id: 4, text: "Water Reduction" },
            { id: 5, text: "Waste Reduction" },
            { id: 6, text: "ข้อมูลเชิงลึก" },
        ],
        dashboard_v2 : "dashboardV2"
    },
    mutations: {

    },
    actions: {

    },
    getters: {
        dashboardTAb: (state) => state.dashboardTab,
        dashboardTabDenso: (state) => state.dashboardTabDenso,
        specialVariableTab: (state) => state.specialVariableTab,
        specialVariableDensoTab: (state) => state.specialVariableDensoTab,
    },
}