<template>
  <styled-modal
    id="modal-form-password"
    :title="titleModal"
    @on-hidden="reset()"
    :hide-close="forcePassword"
  >
    <div class="pt-0">
      <form>
        <div class="mb-3">
          <typo type="body_web" class="mb-1" color="var(--primary-black)">
            {{ $t("LB_OLD_PASSWORD") }}
            <span style="color: red">*</span>
          </typo>
          <div class="form-group m-0">
            <b-form-input
              v-model="form_data.current_password"
              id="current_password"
              :type="!is_show_current_password ? 'password' : 'text'"
              :placeholder="$t('F_OLD_PASSWORD')"
              required
            >
            </b-form-input>
            <eye-icon
              :opened="is_show_current_password"
              @on-click="is_show_current_password = !is_show_current_password"
            />
          </div>
          <typo
            type="small_web"
            v-if="!is_validate && v$.form_data.current_password.$error"
            class="error-text"
            >{{ $t("VA_PLEASE_ENTER_OLD_PASSWORD") }}</typo
          >
        </div>

        <div class="mb-3">
          <typo type="body_web" class="mb-1">
            {{ $t("LB_NEW_PASSWORD") }}
            <span style="color: red">*</span>
          </typo>
          <div class="form-group m-0">
            <b-form-input
              v-model="form_data.new_password"
              id=""
              :type="!is_show_new_password ? 'password' : 'text'"
              @input="checkPassword()"
              :placeholder="$t('F_NEW_PASSWORD')"
              required
            >
            </b-form-input>
            <eye-icon
              :opened="is_show_new_password"
              @on-click="is_show_new_password = !is_show_new_password"
            />
          </div>
          <typo
            type="small_web"
            class="error-text"
            v-if="!is_validate && !v$.form_data.new_password.required.$response"
          >
            {{ $t("VA_PLEASE_ENTER_NEW_PASSWORD") }}
          </typo>
          <typo
            type="small_web"
            class="error-text"
            v-else-if="!is_validate && v$.form_data.new_password.isNoSpace.$invalid"
          >
            {{ $t("TXT_CONTAINS_NO_SPACE") }}
          </typo>
          <typo
            type="small_web"
            class="error-text"
            v-else-if="!is_validate && !valid_password"
          >
            {{ $t("VA_PLEASE_ENTER_CORRECT_PASS_FORM") }}
          </typo>
        </div>

        <div class="mb-3">
          <typo type="body_web" class="mb-1">
            {{ $t("TXT_CONFIRM_PASSWORD") }}
            <span style="color: red">*</span>
          </typo>
          <div class="form-group m-0">
            <b-form-input
              v-model="form_data.confirm_new_password"
              id=""
              :type="!is_show_confirm_password ? 'password' : 'text'"
              @input="checkPassword()"
              :placeholder="$t('F_CONFIRM_PASSWORD')"
              required
            >
            </b-form-input>
            <eye-icon
              :opened="is_show_confirm_password"
              @on-click="is_show_confirm_password = !is_show_confirm_password"
            />
          </div>
          <typo
            type="small_web"
            class="error-text"
            v-if="
              !is_validate &&
              !v$.form_data.confirm_new_password.required.$response
            "
            >{{ $t("VA_PLEASE_ENTER_YOUR_CONFIRM_PASS") }}</typo
          >
          <typo
            type="small_web"
            class="error-text"
            v-else-if="!is_validate && v$.form_data.confirm_new_password.isNoSpace.$invalid"
          >
            {{ $t("TXT_CONTAINS_NO_SPACE") }}
          </typo>
          <typo
            type="small_web"
            class="error-text"
            v-else-if="
              !is_validate &&
              !v$.form_data.confirm_new_password.sameAsPassword.$response
            "
            >{{ $t("VA_ENTER_CORRECT_PASSWORD_CONFIRMATION") }}</typo
          >
        </div>

        <div class="d-flex flex-wrap gap-2 align mt-4 mb-2">
          <span
            :class="{ is_valid: contains_eight_characters }"
            class="d-flex align-items-center gap-2"
          >
            <success-orange-icon v-if="contains_eight_characters" />
            <img
              v-else
              src="@/assets/register/checkemp.svg"
              width="12px"
              height="13px"
              alt="check"
            />
            <typo>{{ $t("TXT_8_CHARACTERS") }}</typo>
          </span>
          <span
            :class="{ is_valid: contains_number }"
            class="d-flex align-items-center gap-2"
          >
            <success-orange-icon v-if="contains_number" />
            <img
              v-else
              src="@/assets/register/checkemp.svg"
              width="12px"
              height="13px"
              alt="check"
            />
            <typo>{{ $t("TXT_CONTAINS_NUMBER") }}</typo>
          </span>
          <span
            :class="{ is_valid: confirm_password }"
            class="d-flex align-items-center gap-2"
          >
            <success-orange-icon v-if="confirm_password" />

            <img
              v-else
              src="@/assets/register/checkemp.svg"
              width="12px"
              height="13px"
              alt="check"
            />
            <typo>{{ $t("TXT_CONFIRM_PASSWORD") }}</typo>
          </span>
          <span
            :class="{ is_valid: contains_lowercase }"
            class="d-flex align-items-center gap-2"
          >
            <success-orange-icon v-if="contains_lowercase" />
            <img
              v-else
              src="@/assets/register/checkemp.svg"
              width="12px"
              height="13px"
              alt="check"
            />
            <typo>{{ $t("TXT_CONTAINS_LOWERCASE") }}</typo>
          </span>
          <span
            :class="{ is_valid: contains_uppercase }"
            class="d-flex align-items-center gap-2"
          >
            <success-orange-icon v-if="contains_uppercase" />
            <img
              v-else
              src="@/assets/register/checkemp.svg"
              width="12px"
              height="13px"
              alt="check"
            />
            <typo>{{ $t("TXT_CONTAINS_UPPERCASE") }}</typo>
          </span>
          <span
            :class="{ is_valid: contains_special_character }"
            class="d-flex align-items-center gap-2"
          >
            <success-orange-icon v-if="contains_special_character" />
            <img
              v-else
              src="@/assets/register/checkemp.svg"
              width="12px"
              height="13px"
              alt="check"
            />
            <typo>{{ $t("TXT_CONTAINS_SPECIAL_CHARACTER") }}</typo>
          </span>
        </div>
      </form>
    </div>
    <template v-slot:footer>
      <div class="d-flex ga justify-content-end mt-3">
        <styled-button
          type="primary"
          @on-click="openConfirmLogout"
          style="width: 161px"
          :disabled="!valid_password"
        >
          {{ $t("BTN_SAVE") }}
        </styled-button>
      </div>
    </template>

    <ModalWarning
      id="modal-update-password"
      :title="$t('TXT_HEADER_CONFIRM_PASSWORD_CHANGE')"
      :content="$t('TXT_SUB_CONFIRM_PASSWORD_CHANGE')"
      :submitFunction="onSave"
    />
  </styled-modal>
</template>
<script>
import { httpClient } from "@/services/axiosInstance.js";
import { apiURL } from "@/configs/serverConfig";
import { required, sameAs } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import StyledModal from "../../Modal/StyledModal.vue";
import ModalWarning from "../../Modal/Alert/ModalWarning.vue";

export default {
  props: ["userType", "forcePassword"],
  components: {
    StyledModal,
    ModalWarning,
  },
  setup: () => ({ v$: useVuelidate() }),
  computed: {
    titleModal() {
      return this.$t("TXT_CHANGE_PASSWORD");
    },
  },
  data() {
    return {
      form_data: {
        current_password: null,
        new_password: null,
        confirm_new_password: null,
      },
      password_length: 0,
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_lowercase: false,
      contains_special_character: false,
      contains_no_space: false,
      confirm_password: false,
      valid_password: false,
      is_validate: true,
      isValidate: false,
      is_show_current_password: false,
      is_show_new_password: false,
      is_show_confirm_password: false,
    };
  },

  validations() {
    return {
      form_data: {
        current_password: { required },
        new_password: {
          required,
          isNoSpace: {
            $validator: this.checkNoSpaces
          },
        },
        confirm_new_password: {
          required,
          sameAsPassword: sameAs(this.form_data.new_password),
          isNoSpace: {
            $validator: this.checkNoSpaces
          },
        },
      },
    };
  },
  // watch: {
  //   contains_eight_characters: "checkIsValidate",
  //   contains_number: "checkIsValidate",
  //   contains_uppercase: "checkIsValidate",
  //   contains_lowercase: "checkIsValidate",
  //   contains_special_character: "checkIsValidate",
  //   confirm_password: "checkIsValidate",
  //   valid_password: "checkIsValidate",
  //   contains_no_space: "checkIsValidate",
  // },
  methods: {
    checkNoSpaces(password) {
      const hasNoSpaces = (password) => password !== "" && !/\s/.test(password);
      return hasNoSpaces(password);
    },
    // checkIsValidate() {
    //   this.isValidate =
    //     this.contains_eight_characters &&
    //     this.contains_number &&
    //     this.contains_uppercase &&
    //     this.contains_lowercase &&
    //     this.confirm_password &&
    //     this.valid_password;
    // },
    reset() {
      this.form_data = {
        current_password: null,
        new_password: null,
        confirm_new_password: null,
      };
      this.password_length = 0;
      this.contains_eight_characters = false;
      this.contains_number = false;
      this.contains_uppercase = false;
      this.contains_lowercase = false;
      this.contains_special_character = false;
      this.contains_no_space = false;
      this.confirm_password = false;
      this.valid_password = false;
      this.is_validate = true;
      this.isValidate = false;
      this.is_show_current_password = false;
      this.is_show_new_password = false;
      this.is_show_confirm_password = false;
    },
    async onSave() {
      // if (!this.isValidate) {
      //   return (this.is_validate = false);
      // }
      const url = `${apiURL}/user/change_password`;
      const data = {
        current_password: this.form_data.current_password,
        new_password: this.form_data.new_password,
      };
      try {
        const res_password = await httpClient.post(url, data);
        if (res_password.status === 200) {
          this.$toast.success({
            component: "styled-toast",
            props: {
              type: "success",
              message: this.$t("TXT_SAVE_DONE"),
            },
          });

          await this.$globalLogout();
          this.redirectOnLogout();
        }
      } catch (err) {
        this.$toast.error({
          component: "styled-toast",
          props: {
            type: "error",
            message: err.response.data.message,
          },
        });
      }
    },
    async openConfirmLogout() {
      const isValidate = await this.v$.$validate();
      // console.log('this.v$', isValidate);
      isValidate && this.valid_password
        ? this.$bvModal.show("modal-update-password")
        : (this.is_validate = false);
    },
    async logout() {
      this.$store.commit("setToken", "");
      this.$store.commit("setRoleAction", null);
      if (this.userType == 2 || this.userType == 3) {
        this.$router.push("/login-auditor");
      } else {
        this.$router.push("/");
      }
    },
    closeModal() {
      this.$bvModal.hide("modal-form-password");
    },
    checkPassword() {
      const { new_password, confirm_new_password } = this.form_data;

      // Helper functions for specific checks
      const hasMinLength = (password) => password.length >= 8;
      const hasNumber = (password) => /\d/.test(password);
      const hasLowercase = (password) => /[a-z]/.test(password);
      const hasUppercase = (password) => /[A-Z]/.test(password);
      const hasSpecialCharacter = (password) =>
        /[!@#$%^&*()\-=+[\]{}|/<>?:";'\\]/.test(password);
      // const hasNoSpaces = (password) => password !== "" && !/\s/.test(password);

      // Perform checks
      this.contains_eight_characters = hasMinLength(new_password);
      this.contains_number = hasNumber(new_password);
      this.contains_lowercase = hasLowercase(new_password);
      this.contains_uppercase = hasUppercase(new_password);
      this.contains_special_character = hasSpecialCharacter(new_password);
      // this.contains_no_space =
      //   hasNoSpaces(new_password) && hasNoSpaces(confirm_new_password);
      this.confirm_password =
        new_password && new_password === confirm_new_password;
      // Check if the password is valid
      this.valid_password =
        this.contains_eight_characters &&
        this.contains_number &&
        // this.contains_no_space &&
        this.contains_special_character &&
        this.contains_uppercase &&
        this.contains_lowercase &&
        this.confirm_password;
      
      // Validate confirm password
      // console.log("contains_eight_characters", this.contains_eight_characters);
      // console.log("contains_number", this.contains_number);
      // console.log("contains_no_space", this.contains_no_space);
      // console.log(
      //   "contains_special_character",
      //   this.contains_special_character
      // );
      // console.log("contains_uppercase", this.contains_uppercase);
      // console.log("contains_lowercase", this.contains_lowercase);
      // this.checkConfirmPassword();
    },
    // checkConfirmPassword() {
    //   const { new_password, confirm_new_password } = this.form_data;
    //   this.confirm_password =
    //     new_password && new_password === confirm_new_password;
    // },
  },
};
</script>
