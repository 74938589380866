const run_mode = process.env.VUE_APP_API_ENV;

let apiUrlConfig = null;

switch (run_mode) {
    // case 'uat': apiUrlConfig = 'https://api.set-carbon-uat.carbonwize.io/api/v1'
    case 'uat': apiUrlConfig = 'https://setcarbon.setstage.or.th/api/v1'
        break;
    case 'develop': apiUrlConfig = 'https://api.set-carbon-dev.carbonwize.io/api/v1'
        break;
    case 'prod': apiUrlConfig = 'https://setcarbon.setgroup.or.th/api/v1'
        break;
    default: apiUrlConfig = 'https://api.set-carbon-dev.carbonwize.io/api/v1'
}

export const apiURL = apiUrlConfig
