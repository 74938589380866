let idleTimeout;
let resetIdleTimer;

export function startIdleTimer(
  callback,
  idleTime = process.env.VUE_APP_IDLE_TIMEOUT * 60 * 1000
) {
  // Define resetIdleTimer outside startIdleTimer
  resetIdleTimer = () => {
    clearTimeout(idleTimeout);
    idleTimeout = setTimeout(() => {
      callback();
    }, idleTime);
  };

  window.addEventListener("mousemove", resetIdleTimer);
  window.addEventListener("keydown", resetIdleTimer);
  window.addEventListener("touchstart", resetIdleTimer);
  resetIdleTimer();
}

export function stopIdleTimer() {
  clearTimeout(idleTimeout);
  window.removeEventListener("mousemove", resetIdleTimer);
  window.removeEventListener("keydown", resetIdleTimer);
  window.removeEventListener("touchstart", resetIdleTimer);
}
