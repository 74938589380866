<template>
  <div>
    <styled-modal
      id="modal-term"
      size="xl"
      :title="'ข้อตกลงและเงื่อนไขการใช้บริการระบบ SET Carbon'"
      style="min-height: 500px"
      :hide-close-btn="hideCloseBtn"
    >
      <div
        style="
          max-height: 60vh;
          overflow-y: auto;
          background: white;
          border-radius: 4px;
          padding: 20px;
        "
      >
        <typo type="body_web"> ข้อตกลงและเงื่อนไขการใช้บริการระบบ </typo>
        <div class="row mt-2">
          <div class="col-12">
            <typo type="bold_web" class="my-2"> 1.คำนิยาม </typo>
          </div>
          <div class="col-3 pb-0 d-flex">
            <typo type="bold_web">“ตลาดหลักทรัพย์”</typo>
          </div>
          <div class="col-9 pb-0">
            <typo type="body_web">หมายความว่า ตลาดหลักทรัพย์แห่งประเทศไทย</typo>
          </div>
          <div class="col-3 pb-0 d-flex">
            <typo type="bold_web">“กลุ่มตลาดหลักทรัพย์ฯ”</typo>
          </div>
          <div class="col-9 pb-0">
            <typo type="body_web"
              >หมายความว่า ตลาดหลักทรัพย์ และบริษัทในกลุ่มตลาดหลักทรัพย์ฯ</typo
            >
          </div>
          <div class="col-3 pb-0 d-flex">
            <typo type="bold_web">“บริษัทในกลุ่มตลาดหลักทรัพย์ฯ”</typo>
          </div>
          <div class="col-9 pb-0">
            <typo type="body_web"
              >หมายความว่า
              บริษัทที่จัดตั้งโดยตลาดหลักทรัพย์หรือบริษัทย่อยของตลาดหลักทรัพย์
              และตลาดหลักทรัพย์ถือหุ้น ไม่ว่าทางตรงหรือทางอ้อม เกินกว่าร้อยละ 50
              (ห้าสิบ) ของทุนจดทะเบียนของบริษัท</typo
            >
          </div>
          <div class="col-3 pb-0 d-flex">
            <typo type="bold_web">“ระบบ SET Carbon”</typo>
          </div>
          <div class="col-9 pb-0">
            <typo type="body_web"
              >หมายความว่า
              ระบบที่ใช้ในการจัดทำข้อมูลก๊าซเรือนกระจกขององค์กรและเว็บไซต์ที่ใช้ในการจัดทำข้อมูล
              เพื่อรวบรวมและวิเคราะห์ข้อมูลการปล่อยก๊าซเรือนกระจกของบริษัท
              เพื่อติดตามและวางแผนการลดการปล่อยก๊าซเรือนกระจก
              และเพิ่มประสิทธิภาพในการพัฒนาธุรกิจอย่างยั่งยืน
            </typo>
          </div>
          <div class="col-3 pb-0 d-flex">
            <typo type="bold_web">“ข้อมูล SET Carbon”</typo>
          </div>
          <div class="col-9 pb-0">
            <typo type="body_web"
              >หมายความว่า ข้อมูลการจัดการก๊าซเรือนกระจก
              ซึ่งรวมถึงแต่ไม่จำกัดเพียงข้อมูลตัวเลขสำคัญทางการเงิน
              ผลการดำเนินงานด้านสิ่งแวดล้อม กิจกรรมของธุรกิจและผลิตภัณฑ์
              ข้อมูลแสดงค่าก๊าซเรือนกระจกที่ปล่อยออกมาต่อหน่วยของกิจกรรมหรือหน่วยของวัตถุดิบ
              ผลการประเมินและคำนวณปริมาณก๊าซเรือนกระจก
            </typo>
          </div>
          <div class="col-3 pb-0 d-flex">
            <typo type="bold_web">“ผู้ใช้งาน”</typo>
          </div>
          <div class="col-9 pb-0">
            <typo type="body_web"
              >หมายความว่า พนักงาน บุคลากร
              และ/หรือผู้ที่อยู่ภายใต้การควบคุมดูแลของบริษัท
              ซึ่งบริษัทได้ตรวจสอบแล้ว
              หรือที่บริษัทได้กำหนดสิทธิให้เป็นผู้ใช้งาน (User) ระบบ SET Carbon
              และสามารถเข้าใช้งานระบบ SET Carbon ได้ในนามของบริษัท
            </typo>
          </div>
          <div class="col-3 pb-0 d-flex">
            <typo type="bold_web">“ข้อมูลส่วนบุคคล”</typo>
          </div>
          <div class="col-9 pb-0">
            <typo type="body_web"
              >หมายความว่า
              ข้อมูลเกี่ยวกับบุคคลธรรมดาซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้
              ไม่ว่าทางตรงหรือทางอ้อม
              แต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรมโดยเฉพาะตามกฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคล
            </typo>
          </div>
          <div class="col-3 pb-0 d-flex">
            <typo type="bold_web">“ประมวลผล”</typo>
          </div>
          <div class="col-9 pb-0">
            <typo type="body_web"
              >หมายความว่า การดำเนินการใด ๆ
              กับข้อมูลส่วนบุคคลไม่ว่าด้วยวิธีการอัตโนมัติหรือไม่ก็ตาม เช่น
              การเก็บรวบรวม การบันทึก การจัดระบบ การจัดเก็บ
              การปรับเปลี่ยนหรือการดัดแปลง การเรียกคืน การปรึกษา การใช้
              การเปิดเผย (โดยการส่ง โอน
              การเผยแพร่หรือการทำให้สามารถเข้าถึงหรือพร้อมใช้งานโดยวิธีใด ๆ)
              การจัดเรียง การนำมารวมกัน การบล็อกหรือจำกัด การลบหรือการทำลาย
            </typo>
          </div>
          <div class="col-3 pb-0 d-flex">
            <typo type="bold_web">“ข้อตกลงการใช้บริการ”</typo>
          </div>
          <div class="col-9 pb-0">
            <typo type="body_web"
              >หมายความว่า ข้อตกลงและเงื่อนไขการใช้บริการรระบบ SET Carbon
              และ/หรือข้อตกลงการใช้งานอื่นใดของตลาดหลักทรัพย์ที่เกี่ยวข้อง
              ทั้งที่กำหนดไว้ในข้อตกลงและเงื่อนไขการใช้บริการระบบ SET Carbon นี้
              และที่ได้กำหนดไว้โดยเฉพาะเจาะจงในส่วนใดส่วนหนึ่งของระบบ SET Carbon
              ทั้งหมด
              โดยให้หมายความรวมถึงข้อตกลงที่จะได้มีการแก้ไขเพิ่มเติมในอนาคตด้วย
            </typo>
          </div>
          <div class="col-12">
            <typo type="bold_web" class="my-2">
              2. ข้อตกลงและเงื่อนไขการใช้บริการระบบ SET Carbon
            </typo>
          </div>
          <div class="col-12 pb-0">
            <typo type="body_web"
              >2.1 บริษัทตกลงและยินยอมให้ตลาดหลักทรัพย์มีสิทธิ
              หรือดำเนินการเพื่อให้ตลาดหลักทรัพย์มีสิทธิในการใช้ ทำซ้ำ ดัดแปลง
              เผยแพร่ และ/หรืออนุญาตให้บุคคลอื่น ในการใช้ ทำซ้ำ ดัดแปลง
              เผยแพร่ข้อมูล SET Carbon ไม่ว่าทั้งหมดหรือบางส่วน
              ไม่ว่าจะเป็นไปเพื่อประโยชน์ในทางการค้าหรือกระทำเพื่อหากำไรหรือไม่ก็ตาม
              โดยไม่จำกัดสิทธิแต่เพียงผู้เดียว (Non Exclusive) ไม่มีค่าสิทธิ
              (Royalty-Free) และเพิกถอนไม่ได้ (Irrevocable)
              อีกทั้งบริษัทตกลงที่จะดำเนินการใด ๆ เพื่อคงไว้ซึ่งสิทธิดังกล่าว
            </typo>
          </div>
          <div class="col-12 pb-0">
            <typo type="body_web"
              >2.2 บริษัทรับรองว่าข้อความ รูปภาพ ตารางข้อมูล กราฟ กราฟิกต่าง ๆ
              เสียง และข้อมูลอื่น ๆ ไม่ว่าจะอยู่ในรูปแบบใด ที่บริษัทบันทึก
              อัพโหลด (Upload) หรือนำมาใช้บนระบบ SET Carbon (“ข้อมูลของบริษัท”)
              เป็นลิขสิทธิ์และทรัพย์สินทางปัญญาของบริษัท
              และ/หรือผู้ให้อนุญาตแก่บริษัท หากในระหว่างระยะเวลาการใช้บริการระบบ
              SET Carbon หรือเมื่อการใช้บริการระบบ SET Carbon สิ้นสุดลง
              ไม่ว่าด้วยเหตุใดก็ตาม
              มีบุคคลภายนอกกล่าวอ้างหรือใช้สิทธิเรียกร้องใด ๆ
              ว่ามีการละเมิดลิขสิทธิ์หรือทรัพย์สินทางปัญญาเกี่ยวกับข้อมูลของบริษัท
              บริษัทตกลงแจ้งให้ตลาดหลักทรัพย์ทราบภายใน 7 (เจ็ด) วัน
              นับแต่วันที่บริษัททราบเรื่องดังกล่าว
              โดยบริษัทต้องดำเนินการทั้งปวงเพื่อให้การกล่าวอ้างหรือเรียกร้องดังกล่าวระงับสิ้นไปโดยเร็ว
              หากตลาดหลักทรัพย์ต้องรับผิดชดใช้ค่าเสียหายแก่บุคคลภายนอก
              เนื่องจากผลแห่งการละเมิดลิขสิทธิ์หรือทรัพย์สินทางปัญญาดังกล่าว
              บริษัทจะเป็นผู้ชดใช้ค่าเสียหาย ค่าปรับ ค่าใช้จ่าย
              รวมทั้งค่าฤชาธรรมเนียมและค่าทนายความทั้งหมดแต่เพียงผู้เดียว
              ทั้งนี้
              ให้หน้าที่และภาระผูกพันในการปกป้องความเสียหายนี้มีผลบังคับใช้ต่อไป
              แม้ว่าการใช้บริการระบบ SET Carbon นี้สิ้นสุดลงแล้วก็ตาม
            </typo>
          </div>
          <div class="col-12 pb-0">
            <typo type="body_web"
              >2.3 บริษัทรับทราบและตกลงจะปฏิบัติตามข้อตกลงการใช้บริการ
              และ/หรือเงื่อนไขและข้อกำหนดอื่นใดที่ตลาดหลักทรัพย์จะกำหนดขึ้นหรือมีการแก้ไขเพิ่มเติมในอนาคตอย่างเคร่งครัด
              นอกจากนี้ บริษัทรับทราบและตกลงว่า
              บริษัทมีหน้าที่ในการกำกับดูแลไม่ให้ผู้ใช้งานกระทำผิดหรือละเมิดข้อตกลงการใช้บริการ
              หากบริษัทพบว่าผู้ใช้งานกระทำผิดหรือละเมิดข้อตกลงการใช้บริการ
              บริษัทต้องแจ้งให้ตลาดหลักทรัพย์ทราบทันทีด้วย
            </typo>
          </div>
          <div class="col-12 pb-0">
            <typo type="body_web"
              >2.4 บริษัทรับทราบและตกลงว่า
              บริษัทมีหน้าที่ในการตรวจสอบรายชื่อของบุคคลที่อยู่ภายใต้บัญชี
              (Account) ของบริษัทว่าเป็นพนักงาน บุคลากร
              และ/หรือผู้ที่อยู่ภายใต้การควบคุมดูแลของบริษัทจริง
              หากบริษัทพบว่ามีการใช้งานโดยบุคคลที่ไม่ใช่พนักงาน บุคลากร
              และ/หรือผู้ที่อยู่ภายใต้การควบคุมดูแลของบริษัทภายใต้บัญชี
              (Account) ของบริษัท บริษัทต้องดำเนินการลบบุคคลดังกล่าวออกจากบัญชี
              (Account)
              ของบริษัททันทีหรือแจ้งให้ตลาดหลักทรัพย์ดำเนินการแทนโดยไม่ชักช้า
            </typo>
          </div>
          <div class="col-12 pb-0">
            <typo type="body_web"
              >2.5 บริษัทรับทราบและตกลงว่าในการใช้บริการระบบ SET Carbon
              ผู้ใช้งานแต่ละรายจะต้องมีรหัสประจำตัว (Username) และรหัสผ่าน
              (Password) ของตนเอง
              ซึ่งรหัสประจำตัวดังกล่าวจะต้องไม่ถูกใช้งานพร้อมกันหรือในขณะเดียวกัน
              (Concurrently) นอกจากนี้
              บริษัทและผู้ใช้งานมีหน้าที่ในการเก็บรักษารหัสประจำตัวและรหัสผ่านไว้เป็นความลับ
              การเข้าใช้งานและทำรายการใด ๆ ที่เกิดขึ้นจากการ Login
              ผ่านรหัสประจำตัวและรหัสผ่านของผู้ใช้งานในระบบ SET Carbon นี้
              ให้ถือว่าการกระทำดังกล่าวถูกต้องสมบูรณ์และเป็นการกระทำของบริษัทเอง
              หากมีความเสียหายใด ๆ เกิดขึ้น ไม่ว่าจะด้วยเหตุใดก็ตาม
              บริษัทจะต้องรับผิดชอบในการกระทำดังกล่าวทุกประการ
              และบริษัทตกลงจะไม่โต้แย้งหรือเรียกร้องให้ตลาดหลักทรัพย์รับผิดชอบชดใช้ค่าเสียหายแทนบริษัททุกกรณี
            </typo>
          </div>
          <div class="col-12 pb-0">
            <typo type="body_web"
              >2.6 บริษัทตกลงจะไม่ใช้บริการระบบ SET Carbon
              ในลักษณะที่เป็นการขัดต่อกฎหมาย หรือก่อให้เกิดความเดือดร้อนรำคาญ
              หรือเป็นการก่อกวนการทำงานหรืออาจทำให้เกิดผลกระทบต่อความน่าเชื่อถือของระบบ
              SET Carbon หรือระบบใด ๆ ของตลาดหลักทรัพย์หรือบุคคลอื่น
              หรือใช้บริการระบบ SET Carbon
              เพื่อการอื่นนอกจากเพื่อวัตถุประสงค์ที่เกี่ยวข้องกับการจัดทำข้อมูล
              SET Carbon หรือไม่ปฏิบัติตามหรือปฏิบัติฝ่าฝืนข้อตกลงการใช้บริการ
            </typo>
          </div>
          <div class="col-12 pb-0">
            <typo type="body_web"
              >2.7 บริษัทตกลงปกป้องตลาดหลักทรัพย์จากความเสียหายใด ๆ
              อันเกิดจากการใช้บริการระบบ SET Carbon
              และ/หรือข้อมูลของบริษัทที่อัปโหลด (Upload) หรือนำมาใช้บนระบบ SET
              Carbon ในกรณีที่เกิดความเสียหายใด ๆ
              ขึ้นกับตลาดหลักทรัพย์หรือบุคคลอื่นใดจากการกระทำดังกล่าว
              บริษัทตกลงชดใช้ค่าเสียหายที่เกิดขึ้นแก่ตลาดหลักทรัพย์จนครบถ้วน
              ซึ่งรวมถึงค่าทนายความด้วย นอกจากนี้ บริษัทรับทราบและตกลงว่า
              ในกรณีที่ตลาดหลักทรัพย์มีเหตุอันควรสงสัยหรือตรวจพบว่าบริษัท
              และ/หรือผู้ใช้งานกระทำการใด ๆ
              ที่มีลักษณะเป็นการฝ่าฝืนหรือไม่ปฏิบัติตามข้อตกลงการใช้บริการ
              และ/หรือเงื่อนไขการให้บริการอื่นใดระหว่างตลาดหลักทรัพย์กับบริษัท
              ตลาดหลักทรัพย์อาจระงับหรือยกเลิกการให้บริการระบบ SET Carbon
              แก่บริษัททันทีตามที่ตลาดหลักทรัพย์พิจารณาเห็นสมควร
              โดยไม่ต้องแจ้งให้บริษัททราบล่วงหน้าและไม่จำเป็นต้องแจ้งหรือชี้แจงเหตุผลให้บริษัททราบ
              รวมทั้งไม่ต้องรับผิดชอบในความเสียหายใด ๆ
              ที่เกิดขึ้นกับบริษัทอันมาจากการระงับการเข้าถึงหรือยกเลิกการให้บริการเพราะเหตุดังกล่าว
              ทั้งนี้
              การระงับหรือยกเลิกดังกล่าวไม่ถือเป็นการตัดสิทธิตลาดหลักทรัพย์ที่จะได้รับการเยียวยาตามข้อตกลงการใช้บริการนี้
              รวมถึงสิทธิอื่นใดตามกฎหมายที่เกี่ยวข้อง
            </typo>
          </div>
          <div class="col-12 pb-0">
            <typo type="body_web"
              >2.8
              ตลาดหลักทรัพย์จะดำเนินการอย่างเหมาะสมเพื่อให้การเข้าถึงและการใช้บริการระบบ
              SET Carbon นี้ เป็นไปอย่างต่อเนื่อง ไม่หยุดชะงัก
              และจะจัดให้มีระบบป้องกันการเข้าถึงข้อมูลใด ๆ
              จากบุคคลที่ไม่ใช่บริษัทหรือผู้ใช้งานของบริษัท อย่างไรก็ดี
              ตลาดหลักทรัพย์ไม่อาจรับรองได้ว่าการใช้บริการระบบ SET Carbon
              จะเป็นไปอย่างต่อเนื่อง ไม่หยุดชะงัก
              หรือปราศจากความล่าช้าหรือข้อบกพร่องใด ๆ และไม่อาจรับรองว่าระบบ SET
              Carbon นี้ จะปราศจากโปรแกรม ไฟล์
              หรือซอฟต์แวร์ที่มีจุดประสงค์มุ่งร้าย
              หรืออาจสร้างความเสียหายหรือสูญหายต่อระบบหรือข้อมูลของบริษัท
              และ/หรือผู้ใช้งาน อาทิ ไวรัส คอมพิวเตอร์เวิร์ม โทรจันฮอร์ส
              สปายแวร์ หรือไฟล์ซึ่งเป็นอันตรายอื่น ๆ (รวมเรียกว่า “ความบกพร่อง”)
              ทั้งนี้ ไม่ว่าความบกพร่องดังกล่าวจะเกิดขึ้นจากบุคคล
              หรือความขัดข้องทางเทคนิค หรือเหตุสุดวิสัยอย่างอื่น ดังนั้น
              บริษัทจึงรับทราบเงื่อนไขดังกล่าวและตกลงว่าในกรณีที่มีความบกพร่องใด
              ๆ เกิดขึ้น ตลาดหลักทรัพย์จะไม่รับผิดชอบในความสูญหาย ความเสียหาย
              ค่าสินไหมทดแทน ค่าใช้จ่าย หรือค่าเสียหายใด ๆ
              ที่เกิดจากหรือเป็นผลสืบเนื่องมาจากความบกพร่องเหล่านั้นในทุกกรณี
            </typo>
          </div>
          <div class="col-12 pb-0">
            <typo type="body_web"
              >2.9
              บริษัทรับทราบและตกลงว่าตลาดหลักทรัพย์สงวนสิทธิ์ที่จะแก้ไขเพิ่มเติมข้อตกลงการใช้บริการเมื่อใดก็ได้
              เพื่อให้สอดคล้องกับแนวทางการใช้งานระบบ SET Carbon
              และหลักเกณฑ์ของกฎหมายที่มีการเปลี่ยนแปลงไป
              โดยอาจไม่ได้แจ้งหรือบอกกล่าวให้ทราบล่วงหน้า ดังนั้น
              บริษัทตกลงจะติดตามข้อตกลงการใช้บริการอยู่เสมอ อย่างไรก็ดี
              ตลาดหลักทรัพย์อาจดำเนินการตามที่เห็นสมควรเพื่อเผยแพร่การเปลี่ยนแปลงข้อตกลงการใช้บริการ
              ในกรณีที่มีการเปลี่ยนแปลงในสาระสำคัญ
              ตลาดหลักทรัพย์จะดำเนินการอย่างเต็มที่เพื่อแจ้งให้บริษัททราบ
            </typo>
          </div>
          <div class="col-12 pb-0">
            <typo type="body_web"
              >2.10
              บริษัทรับทราบและตกลงว่าข้อมูลส่วนบุคคลของบุคคลที่สามที่บริษัทเปิดเผยให้แก่กลุ่มตลาดหลักทรัพย์ฯ
              จะถูกประมวลผลและได้รับความคุ้มครองภายใต้คำประกาศเกี่ยวกับความเป็นส่วนตัวของกลุ่มตลาดหลักทรัพย์ฯ
              (https://www.set.or.th/th/privacy-notice)
              โดยบริษัทได้รับทราบและตกลงว่าบริษัทได้ศึกษารายละเอียดต่าง ๆ
              ที่ระบุไว้ในคำประกาศเกี่ยวกับความเป็นส่วนตัวดังกล่าวเป็นอย่างดีแล้ว
              และบริษัทขอยืนยันและรับรองว่าบริษัทได้ดำเนินการให้บุคคลที่สามได้อ่านรายละเอียดที่ระบุไว้ในคำประกาศเกี่ยวกับความเป็นส่วนตัวดังกล่าวแล้ว
              รวมทั้งได้รับความยินยอมจากบุคคลที่สามในการให้ข้อมูลส่วนบุคคลของบุคคลที่สามนั้นแก่กลุ่มตลาดหลักทรัพย์ฯ
              อย่างถูกต้องและเป็นไปตามกฎหมายทุกประการ
            </typo>
          </div>
          <div class="col-12 pb-0">
            <typo type="body_web"
              >2.11 บริษัทรับทราบและตกลงว่า
              ตลาดหลักทรัพย์เป็นเพียงผู้ให้บริการระบบ SET Carbon
              ซึ่งมีวัตถุประสงค์เพื่ออำนวยความสะดวกแก่บริษัทในการรวบรวมและวิเคราะห์ข้อมูลการปล่อยก๊าซเรือนกระจกของบริษัทเท่านั้น
              โดยตลาดหลักทรัพย์ไม่ได้รับรองว่าข้อมูลที่เกิดจากการประมวลข้อมูลที่บริษัทได้กรอกหรือบันทึกผ่านระบบ
              SET Carbon จะมีความครบถ้วนหรือถูกต้องหรือปราศจากความผิดพลาดใด ๆ
              นอกจากนี้ การที่ตลาดหลักทรัพย์ให้บริการระบบ SET Carbon
              แก่บริษัทนั้น
              ไม่ทำให้ตลาดหลักทรัพย์เป็นนายหน้าหรือตัวแทนของบริษัทแต่อย่างใด
              ทั้งนี้
              บริษัทรับทราบและยินยอมให้ตลาดหลักทรัพย์มอบหมายให้บุคคลอื่นใดในการให้บริการหรือพัฒนาระบบ
              SET Carbon หรือดำเนินการอื่นใดที่เกี่ยวข้องกับระบบ SET Carbon
              แทนตลาดหลักทรัพย์ได้ตามที่ตลาดหลักทรัพย์เห็นสมควร
            </typo>
          </div>
          <div class="col-12 pb-0">
            <typo type="body_web"
              >2.12 บริษัทรับทราบและตกลงว่า บรรดาข้อมูลที่ปรากฏในระบบ SET Carbon
              นี้ ซึ่งรวมถึงแต่ไม่จำกัดเฉพาะ ข้อความ รูปภาพ ตารางข้อมูล กราฟ
              ราคาหรือมูลค่าหลักทรัพย์ (ถ้ามี) กราฟิกต่าง ๆ เสียง
              การออกแบบหน้าจอระบบ SET Carbon การออกแบบส่วนต่อประสานกับผู้ใช้
              (Interface) ข้อมูลต่าง ๆ ไม่ว่าจะอยู่ในรูปแบบใด โปรแกรม
              ซอฟท์แวร์ใด ๆ ที่มีอยู่ในระบบ SET Carbon นี้ ชื่อทางการค้า
              เครื่องหมายการค้า เครื่องหมายบริการ (รวมเรียกว่า “เนื้อหา”)
              ถือเป็นสิทธิของตลาดหลักทรัพย์หรือผู้ให้อนุญาตแก่ตลาดหลักทรัพย์
              ซึ่งเนื้อหาเหล่านั้นได้รับความคุ้มครองด้านทรัพย์สินทางปัญญา
              และ/หรือสิทธิในความเป็นเจ้าของอื่น ๆ ตามกฎหมายของประเทศไทย
              และ/หรือกฎหมายของประเทศอื่นไม่ว่าในรูปแบบใด ๆ
              และไม่ว่าจะได้รับการจดทะเบียนไว้แล้วหรือไม่ก็ตาม ทั้งนี้
              การนำชื่อทางการค้า เครื่องหมายการค้า เครื่องหมายบริการ
              และเครื่องหมายอื่น ๆ ของตลาดหลักทรัพย์ รวมถึงทรัพย์สินทางปัญญาใด ๆ
              ที่ปรากฏในระบบ SET Carbon ไปใช้ ไม่ว่าจะด้วยวัตถุประสงค์ใด ๆ
              บริษัทจะต้องได้รับความยินยอมล่วงหน้าเป็นลายลักษณ์อักษรจากตลาดหลักทรัพย์ก่อนดำเนินการ
            </typo>
          </div>
          <div class="col-12 pb-0">
            <typo type="body_web"
              >2.13 เนื้อหาที่แสดงบนระบบ SET Carbon นี้
              ถูกนำเสนอตามสภาพที่ได้รับ (“as is” basis)
              โดยตลาดหลักทรัพย์ไม่อาจรับรองหรือรับประกันใด ๆ
              ไม่ว่าทางตรงหรือทางอ้อมในเรื่องใด
              ไม่ว่าจะเป็นการรับประกันด้านความครบถ้วน ความถูกต้องเหมาะสม
              ความเป็นปัจจุบัน หรือความน่าเชื่อถือของเนื้อหาและข้อมูล
              หรือการรับประกันผลที่จะได้รับจากการใช้เนื้อหาหรือการใช้บริการระบบ
              SET Carbon นี้ รวมถึงความเหมาะสมในการใช้งาน
              และตลาดหลักทรัพย์ไม่ต้องรับผิดชอบในความสูญหาย หรือความเสียหายใด ๆ
              รวมถึงไม่รับผิดชอบต่อผลกำไรหรือขาดทุนที่เกิดจากการใช้บริการระบบ
              SET Carbon และ/หรือจากการนำเนื้อหาข้อมูลที่ปรากฏในระบบ SET Carbon
              นี้ไปใช้ในทุกกรณี
            </typo>
          </div>
          <div class="col-12 pb-0">
            <typo type="body_web"
              >2.14 บริษัทรับทราบและตกลงว่า ตลาดหลักทรัพย์ไม่รับรองในความถูกต้อง
              ความครบถ้วน ความเป็นปัจจุบัน ความเหมาะสม
              หรือความน่าเชื่อถือของเนื้อหาในระบบ SET Carbon
              โดยตลาดหลักทรัพย์จะไม่รับผิดต่อความสูญหาย ความเสียหาย
              ค่าสินไหมทดแทน ค่าใช้จ่าย หรือค่าเสียหายใด ๆ
              ที่เกิดขึ้นจากการใช้บริการระบบ SET Carbon
            </typo>
          </div>
          <div class="col-12 pb-0">
            <typo type="body_web"
              >2.15 ตลาดหลักทรัพย์สงวนสิทธิ์ในการปรับปรุง แก้ไข
              หรือเปลี่ยนแปลงระบบ SET Carbon
              ไม่ว่าบางส่วนหรือทั้งหมดและไม่ว่าในเวลาใด ๆ
              โดยไม่จำเป็นต้องบอกกล่าวล่วงหน้าหรือระบุเหตุผลในการดำเนินการนั้น
            </typo>
          </div>
          <div class="col-12 pb-0">
            <typo type="body_web"
              >2.16
              บริษัทรับทราบและตกลงให้ข้อตกลงการใช้บริการอยู่ภายใต้บังคับและตีความตามกฎหมายไทย
              และศาลไทยเป็นผู้มีอำนาจในการพิจารณาข้อพิพาทใดที่อาจเกิดขึ้น
            </typo>
          </div>
          <div class="col-12 pb-0">
            <typo type="body_web"
              >2.17 บริษัทรับทราบและตกลงว่า ระบบ SET Carbon
              อาจมีการเชื่อมโยงกับระบบงานอื่นของตลาดหลักทรัพย์
              และ/หรือระบบงานของบุคคลอื่น
              ซึ่งเป็นไปเพื่ออำนวยความสะดวกแก่บริษัทเท่านั้น
              โดยบริษัทรับทราบและตกลงว่า
              ตลาดหลักทรัพย์ไม่ได้รับรองว่าข้อมูลของบริษัทที่เชื่อมโยงดังกล่าวครบถ้วนหรือถูกต้อง
              และบริษัทมีหน้าที่ในการตรวจสอบข้อมูลดังกล่าวทุกครั้ง นอกจากนี้
              บริษัทรับทราบและตกลงตามข้อจำกัดและเงื่อนไขของการเชื่อมโยงข้อมูลจากระบบ
              SET Carbon ดังนี้
            </typo>
            <ul class="mt-3" style="list-style: none;">
              <li>
                (1) ในการเชื่อมโยงข้อมูลจากระบบ SET Carbon
                ไปยังระบบงานอื่นของตลาดหลักทรัพย์ บริษัทรับทราบและตกลงว่า
                บริษัทยังมีหน้าที่ในการปฏิบัติตามข้อตกลงของระบบงานอื่นของตลาดหลักทรัพย์ที่ได้เชื่อมโยงข้อมูลนั้นด้วย
              </li>
              <li class="mt-2">
                (2) ในการเชื่อมโยงข้อมูลจากระบบ SET Carbon
                ไปยังระบบงานของบุคคลอื่น บริษัทรับทราบและตกลงว่า
                ระบบงานที่เชื่อมโยงนี้มิได้แสดงว่าได้รับการสนับสนุนหรืออยู่ภายใต้การควบคุม
                หรือความรับผิดชอบของตลาดหลักทรัพย์ ดังนั้น
                ตลาดหลักทรัพย์จึงไม่อาจให้การรับรองในความถูกต้อง ความน่าเชื่อถือ
                หรือความปลอดภัยของระบบงานที่เชื่อมโยง
                และจะไม่มีความรับผิดในความสูญหายหรือเสียหายใด ๆ
                ที่บริษัทจดทะเบียนได้รับจากการใช้งานหรือเชื่อมโยงระบบงานดังกล่าว
                และขอให้บริษัทโปรดศึกษาข้อตกลงและเงื่อนไขการใช้งานของระบบงานที่เชื่อมโยงแต่ละแห่งนั้นด้วย
              </li>
            </ul>
          </div>
        </div>
      </div>
      <template v-if="isShowBtnSubmit" v-slot:footer>
        <div class="d-flex flex-column gap-2 justify-content-end w-100">
          <div class="d-flex gap-2 justify-content-center w-100">
            <input
              v-model="is_accept_term"
              type="checkbox"
              class="form-check-input"
            />
            ยอมรับข้อตกลงและเงื่อนไข
          </div>
          <div class="d-flex gap-2 justify-content-between w-100">
            <div>
              <styled-button
                type="secondary"
                style="width: 161px"
                @on-click="closeModal"
              >
                {{ $t("BTN_CANCEL") }}
              </styled-button>
            </div>
            <div>
              <styled-button
                type="primary"
                :disabled="!is_accept_term"
                style="width: 161px"
                @on-click="acceptTerm()"
              >
                {{ $t("BTN_CONFIRM") }}
              </styled-button>
            </div>
          </div>
        </div>
      </template>
    </styled-modal>
  </div>
</template>
<script>
import helper from "@/mixins/helper";
import StyledModal from "@/components/Modal/StyledModal.vue";

export default {
  mixins: [helper],
  props: ["isShowBtnSubmit", "hideCloseBtn"],
  components: {
    StyledModal,
  },
  data() {
    return {
      data_standard: [{ id: 1, label: "TGO" }],
      search: "",
      filter_standard: undefined,
      limit: 15,
      total_pages: 1,
      current_page: 1,
      lists_verify: null,
      auditor_detail: {
        company: "",
        auditor_name: "",
        auditor_type: "",
      },
      select_verify: [],
      debounce: null,
      mode: "",
      is_accept_term: false,
    };
  },
  created() {},
  methods: {
    closeModal() {
      this.$bvModal.hide("modal-term");
      this.$emit("logoutWithTerm");
    },
    acceptTerm() {
      this.$emit("acceptTerm", this.is_accept_term);
    },
  },
};
</script>
<style lang="scss">
#modal-term___BV_modal_content_ {
  background: #f2f2f2;
}
</style>
