export default {
  namespaced: true,
  state: {
    status_verify: null,
    status_notify: false,
  },
  mutations: {
    setStatusVerify: (state, payload) => {
      state.status_verify = payload;
    },
    setStatusNotify: (state, payload) => {
      state.status_notify = payload;
    },
  },
  actions: {
    setStatusVerify: (state, payload) => {
      state.commit("setStatusVerify", payload);
    },
    setStatusNotify: (state, payload) => {
      state.commit("setStatusNotify", payload);
    },
  },
  getters: {
    statusVerify: (state) => state.status_verify,
    statusNotify: (state) => state.status_notify,
  },
};
